import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import styles from '../../../../css/CandidateDetailsMobile.module.scss';
import { ReactComponent as BackIcon } from '../../../../assets/backArrow.svg';
import { ReactComponent as DownloadButton } from '../../../../assets/downloadFrame.svg';
import CandidateVideoResponse from '../../../../components/CandidateVideoResponse';
import {
  CANDIDATE_MOBILE_PURCHASE_BANNER_USD,
  CANDIDATE_MOBILE_PURCHASE_BANNER_INR,
} from '../../../../utils/constants';
import {
  currentCountryUsingIp,
  // mxTrackEvent
} from '../../../../utils/utils';
import CandidateScore from '../../../../components/CandidateScore';
import CandidateActions from '../../../../actions/CandidateActions';

const PurchaseBanner = () => {
  const history = useHistory();
  const getCurrentCountry = async () => {
    const country = await currentCountryUsingIp();
    return country;
  };

  const image = getCurrentCountry() === 'india' ? CANDIDATE_MOBILE_PURCHASE_BANNER_INR : CANDIDATE_MOBILE_PURCHASE_BANNER_USD;
  const handleReadyForPurchase = () => {
    console.log('Ready for purchase');
    history.push('/user/billing');
  };
  return (
    <div
      style={{
        backgroundImage: `url(${image})`,
        height: '29rem',
        width: '100%',
        padding: '0em',
        minWidth: '100%',
        minHeight: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'relative',
        marginBottom: '2em',
      }}
    >
      <button
        type="button"
        className={styles.learnMoreButton}
        onClick={handleReadyForPurchase}
      >
        Learn more
      </button>
    </div>
  );
};

const CandidateDetailsNew = (props) => {
  const purchaseBannerRef = useRef(null);

  const {
    candidateProfile,
    interviewId,
    candidateId,
    isLocked,
    isCoaching,
  } = props; //eslint-disable-line
  const {
    companyLogoUrl,
    candidateName,
    interviewname,
  } = candidateProfile;

  return (
    <React.Fragment>
      <div className={styles.mobileViewHeader}>
        <div className={styles.mobileViewHeaderActionsSection}>
          <button
            type="button"
            className={styles.backSection}
            onClick={() => window.history.back()}
          >
            <BackIcon />
          </button>
          <button
            type="button"
            className={styles.downloadReportButton}
            onClick={() => {
              if ((!isLocked || isCoaching)) {
                // mxTrackEvent('Interest expressed in download reports', {
                //   user: candidateProfile.email,
                // });
                purchaseBannerRef.current.scrollIntoView({ behavior: 'smooth' });
                return;
              }
              CandidateActions.downloadCandidateReport(
                interviewId,
                candidateId,
                candidateProfile.interviewname,
              );
            }
            }
          >
            <DownloadButton />
          </button>
        </div>
        <div className={styles.jobCompanyInfo}>
          <h4>{interviewname}</h4>
          <div className={styles.companyDetail}>
            <img src={companyLogoUrl} alt="candidate" className={styles.candidateImage} />
            <span>{candidateName}</span>
          </div>
        </div>
      </div>
      <div className={styles.questionSection}>
        <CandidateVideoResponse interviewId={interviewId} candidateId={candidateId} />
      </div>
      <div className={styles.scoresContainer}>
        {
          // eslint-disable-next-line no-constant-condition
          (true || (!isLocked || isCoaching)) ? (
            <CandidateScore
              aiScore={candidateProfile.scores.aiScores}
              workmapScore={candidateProfile.scores.workmap}
              resume={candidateProfile.scores.resume}
              resumeMandatory={candidateProfile.resumeMandatory}
              workmapEnabled={candidateProfile.workmapEnabled}
              totalCommunicationScore={candidateProfile.totalCommunicationScore}
              orgId={candidateProfile.orgId}
            />
          ) : (
            <div ref={purchaseBannerRef} className={styles.bannerContainer}>
              <PurchaseBanner />
            </div>
          )
        }

      </div>
    </React.Fragment>
  );
};


CandidateDetailsNew.propTypes = {
  isCoaching: PropTypes.bool.isRequired,
  isLocked: PropTypes.bool.isRequired,
  candidateId: PropTypes.string.isRequired,
  interviewId: PropTypes.string.isRequired,
  candidateProfile: PropTypes.shape({
    email: PropTypes.string.isRequired,
    interviewname: PropTypes.string.isRequired,
    candidateName: PropTypes.string.isRequired,
    companyLogoUrl: PropTypes.string.isRequired,
    resumeMandatory: PropTypes.bool.isRequired,
    workmapEnabled: PropTypes.bool.isRequired,
    orgId: PropTypes.string.isRequired,
    scores: PropTypes.shape({
      resume: PropTypes.shape({
        overall: PropTypes.number.isRequired,
        experience: PropTypes.number.isRequired,
        skill: PropTypes.number.isRequired,
        education: PropTypes.number.isRequired,
      }),
      workmap: PropTypes.shape({}),
      aiScores: PropTypes.shape({}),
    }),
    totalCommunicationScore: PropTypes.number.isRequired,
  }).isRequired,
};

export default withTranslation()(withRouter(CandidateDetailsNew));
