export default {
  helloApplicant: 'Olá Candidato,',
  thankYou: 'Obrigado.',
  question: 'Pergunta',
  start: 'Iniciar',
  submitting: 'Enviando',
  submit: 'Enviar',
  sending: 'Enviando',
  proceed: 'Prosseguir',
  close: 'Fechar',
  poweredBy: 'Desenvolvido por',
  contactUs: 'Contate-nos',
  showMore: 'Mostrar Mais',
  showLess: 'Mostrar Menos',
  applying: 'Candidatando',
  apply: 'Candidatar',
  click: 'Clique',
  done: 'Concluído',
  uploading: 'Carregando',
  cantFindPage: 'Não conseguimos encontrar a página que você está procurando.',
  usefulLinks: 'Aqui estão alguns links que podem ser úteis',
  menuHome: 'Início',
  menuAboutUs: 'Sobre Nós',
  menuTnC: 'Termos e Condições',
  menuPrivacy: 'Privacidade',
  tnc1: 'Ao marcar esta caixa, você concorda com os ',
  tnc3: 'Ao marcar esta caixa, você concorda com os ',
  tnc2: 'Termos de Uso do Candidato Interviewer.AI',
  amInterested: 'Tenho interesse',
  second: 'segundo',
  second_plural: 'segundos',
  secondWithCount: '{{count}} segundo',
  secondWithCount_plural: '{{count}} segundos',
  minute: 'minuto',
  minute_plural: 'minutos',
  minuteWithCount: '{{count}} minuto',
  minuteWithCount_plural: '{{count}} minutos',
  workmapScreen: {
    greeting: 'Prezado(a) {{name}},',
    unansweredQuestions: 'Suas perguntas não respondidas são:',
    description:
      'Você foi convidado para participar de uma Avaliação WorkMap (Habilidades) para a vaga de {{jobName}} na {{companyName}}.',
    instruction: 'Instruções',
    instruction1:
      'Por favor, responda cada afirmação baseado em como você tem sido na maior parte do seu tempo no trabalho.',
    instruction2:
      'Ao responder as afirmações, selecione a opção que reflete como você geralmente pensa ou se sente.',
    instruction3:
      'Não existem respostas certas ou erradas nesta avaliação, então não gaste muito tempo em uma única resposta.',
    instruction4:
      'Seja espontâneo e tente não responder baseado no que você acha que queremos ouvir.',
    instruction5:
      'Seja honesto em suas respostas. Suas respostas afetarão a precisão da sua avaliação.',
    instruction6:
      'Esta avaliação deve levar aproximadamente 5 minutos para ser concluída. Clique no botão "Iniciar" quando estiver pronto. Boa sorte!',
    goBack: 'Voltar',
    skillsAssessment: 'Avaliação de Habilidades',
    of: 'de',
    answers: 'Respostas',
    questions: 'perguntas',
    submit: 'Enviar',
    start: 'Iniciar',
    instruction7:
      'Você ainda tem perguntas não respondidas. Por favor, complete todas as perguntas antes de enviar.',
    stronglyDisagree: 'Discordo Totalmente',
    disagree: 'Discordo',
    somewhatDisagree: 'Discordo Parcialmente',
    neitherAgreeNorDisagree: 'Nem Concordo Nem Discordo',
    somewhatAgree: 'Concordo Parcialmente',
    agree: 'Concordo',
    stronglyAgree: 'Concordo Totalmente',
    aiaInstruction1: '',
    aiaInstruction2: '',
    aiaInstruction3: '',
  },
  scale: {
    'Not at all': 'Nunca',
    Rarely: 'Raramente',
    Sometimes: 'Às vezes',
    Often: 'Frequentemente',
    'Very Often': 'Muito Frequentemente',
    'Strongly Disagree': 'Discordo Totalmente',
    'Somewhat Disagree': 'Discordo Parcialmente',
    Disagree: 'Discordo',
    'Neither Agree nor Disagree': 'Nem Concordo Nem Discordo',
    Neutral: 'Neutro',
    Agree: 'Concordo',
    'Somewhat Agree': 'Concordo Parcialmente',
    'Strongly Agree': 'Concordo Totalmente',
  },
  applySendSuccessful: {
    thankyou: 'Obrigado pela sua candidatura!',
    instruction:
      'Enviamos um e-mail com o link para a página da entrevista. Recomendamos que você faça a gravação nas próximas 48 horas para maximizar suas chances de ser convidado para a próxima fase. Boa sorte!',
    instruction1: '',
    instruction2: '',
    instruction3: '',
  },
  applySuccessful: {
    congrats: 'Olá, {{name}}!',
    submitted: 'Obrigado pelas informações fornecidas.',
    whatsNext: 'O que há de novo?',
    instruction:
      'O próximo passo no seu processo de candidatura é gravar respostas em vídeo para as perguntas da entrevista.',
    coachingInstruction:
      'Como parte da avaliação online, você precisa gravar resposta(s) em vídeo para a(s) pergunta(s) da avaliação',
    instruction1:
      'Escolha um local silencioso e garanta que seu áudio e iluminação estejam claros',
    instruction2: 'Verifique se não há problemas de conexão antes de começar',
    instruction3:
      'Seja confiante e fale alto o suficiente para que o áudio seja bem captado',
    instruction4:
      'As entrevistas em vídeo devem ser completadas em um tempo razoável. Não demore!',
    instruction5: 'Aguardamos suas respostas em vídeo. Boa sorte!',
    giveBestShot:
      'Esta é sua oportunidade de mostrar sua personalidade, paixão e energia, então dê o seu melhor!',
    helpfulTips: 'Dicas Úteis:',
    doWithin48: 'Faça dentro de 48 horas - envie o link para mim',
    wsgClientInstruction1:
      '5. Por favor, note que a WSG irá analisar todos os vídeos gravados antes de enviar aos empregadores. Vídeos que contenham ou exibam comportamento inadequado ou não profissional, como uso de palavras ofensivas, fundo inadequado, não serão enviados aos empregadores para processamento da(s) candidatura(s). Os candidatos serão notificados para revisar seu vídeo gravado e enviar novamente.',
    doNow: 'Começar agora',
    infoProvide: 'Obrigado pelas informações fornecidas',
    opportunityText:
      'Esta é sua oportunidade de mostrar sua personalidade, paixão e energia, então dê o seu melhor!',
    quietSpot: 'Encontre um bom espaço',
    strongNetwork: 'Rede forte',
    confidentLoud: 'Fale claramente',
    videoCompleted: 'Envios',
  },
  applySuccessfulDelayInterview: {
    thankyou: 'Obrigado {{name}}!',
    submitted: 'Sua candidatura foi enviada.',
    instruction:
      'Nossa equipe analisará sua candidatura e entrará em contato em breve se você for pré-selecionado para a próxima fase. Observe que apenas os candidatos pré-selecionados serão notificados.',
    goodLuck: 'Boa sorte!',
  },
  candidateProfile: {
    intro: 'Apresente-se',
    instruction:
      'Antes de fazer a entrevista em vídeo, pedimos que você se apresente.',
    placeholder: 'Conte-nos sobre você',
  },
  browser: {
    worksBest:
      'O Interviewer.AI funciona melhor no Chrome e Firefox no momento.',
    bestExperience:
      'Estamos trabalhando para garantir que todos tenham a melhor experiência na entrevista!',
    meantime:
      'Enquanto isso, instale a versão mais recente do Google Chrome ou Mozilla Firefox e acesse o mesmo link de entrevista:',
    downloadChrome: 'Baixar Chrome',
    downloadFirefox: 'Baixar Firefox',
    lookForward: 'Aguardamos suas respostas!',
    bestRegards: 'Atenciosamente,',
    interviewerTeam: 'Equipe Interviewer.AI',
    scanQrCode: 'Escaneie o código QR para gravar no celular',
  },
  digitalProfile: {
    interviews: 'Entrevistas',
    interviewName: 'Nome da Entrevista',
    status: 'Status',
    dateResponded: 'Data de Resposta',
    summary: 'Resumo',
    topSkills: 'Principais Habilidades',
    viewResume: 'Ver Currículo',
    visitLinkedin: 'Visitar Linkedin',
    myWorkmapScore: 'Minha Pontuação Workmap',
  },
  organisationIntroVideoScreen: {
    continue: 'Continuar',
    note: '(Obs: O botão Continuar será habilitado após assistir o vídeo completo)',
  },
  endedScreen: {
    recordAgain: 'Gravar Novamente',
    recordAgainQuestion: 'Tem certeza que deseja gravar novamente?',
    recordAgainDescription:
      'Observe que isso exigirá que você refaça toda a entrevista desde a pergunta 1. Pressione o botão Gravar Novamente para reiniciar, ou você pode fechar esta janela e prosseguir para enviar suas respostas.',
    thankyou: 'Obrigado {{name}}! Você concluiu a entrevista.',
    coachingThankyou: 'Obrigado {{name}}! Você concluiu a avaliação.',
    instruction1: 'Você pode revisar suas respostas acima antes de',
    instruction2: ' enviá-las com o botão na parte inferior da página.',
    getFeedback:
      'Para nos ajudar a melhorar nosso produto, agradecemos seu feedback abaixo! O que você achou da experiência de gravação?',
    feedback: 'Feedback',
    placeholder: 'Seu feedback é muito importante!',
    rateExp: 'Avalie sua experiência na entrevista',
    Hi: 'Olá!',
    checkVideo: 'Vamos verificar seu vídeo.',
    question: 'Perguntas',
    submit: 'Enviar',
  },
  thankyouEndScreen: {
    radioText1: 'Não tenho interesse na vaga',
    radioText2: 'Não tenho interesse em entrevista por vídeo',
    radioText3: 'Farei mais tarde',
    radioText4: 'Não consegui enviar',
    radioText5: 'Outros',
    pleaseChooseReason: 'Por favor, escolha um motivo',
    stateYourReason: 'Por favor, informe seu motivo',
    notFinished: 'Você não concluiu a entrevista.',
    notSubmittedVideoResp: 'Sua resposta em vídeo não foi enviada.',
    noSubmittion: 'Sem Envio!',
    shareYourFeedback: 'Por favor, compartilhe seu feedback.',
    tellUsQuittingInterview:
      'Diga-nos se você está desistindo da entrevista ou enfrentando algum desafio.',
    ratingStarsMandatory: 'A avaliação por estrelas é obrigatória',
    pleaseDontLeaveField: 'Por favor, não deixe este campo em branco',
    congratulations: 'Parabéns',
    responseSent: 'Suas respostas em vídeo foram enviadas com sucesso.',
    instruction1:
      'Aguarde até receber uma mensagem de envio bem-sucedido, pois sua candidatura pode estar em processamento',
    instruction2:
      'Fazemos o possível para avaliar sua resposta em minutos. No entanto, pode levar entre 30 minutos a 2 horas. Por favor, aguarde o processamento.',
    instruction3:
      'Você receberá um e-mail quando for avaliado. Verifique sua pasta de spam caso não receba um e-mail em 2 horas.',
    instruction4:
      'Você pode nos contatar em support@interviewer.ai para quaisquer dúvidas adicionais. Boa entrevista!',
    finishedInterview: 'Você concluiu a entrevista.',
    shareExperience: 'Compartilhe sua experiência de gravação.',
    submit: 'Enviar',
    thankyou: 'Obrigado',
    feedbackText:
      'Para nos ajudar a melhorar nosso produto, agradecemos seu feedback abaixo! O que você achou da experiência de gravação?',
    feedback: 'Feedback',
  },
  interviewCompleted: {
    submitted: 'Você enviou uma resposta para a entrevista.',
    thankyou:
      'Obrigado pela sua resposta! Se tiver alguma dúvida, sinta-se à vontade para entrar em contato conosco :)',
  },
  interviewFailure: {
    errorMessage:
      'Notamos que o link que você acessou é inválido ou não existe. Caso você tenha recebido este link de um empregador, entre em contato diretamente com o empregador para obter um novo link.',
    title: 'Ops, o link da entrevista parece ser inválido!',
    contactUsBody:
      'Não foi possível acessar o link da entrevista em vídeo&body=Olá, não consegui acessar o link da entrevista: {{- link }}. Diz que o link da entrevista parece ter sido fechado pelo empregador. Você poderia me ajudar a verificar?',
  },
  interviewSignUp: {
    name: 'Nome',
    apply: 'Candidate-se Agora',
    submit: 'Enviar',
    experience: 'Experiência',
    seniorityLevel: 'Nível de Senioridade',
    employmentType: 'Tipo de Contratação',
    roleOverview: 'Visão Geral',
    companyName: 'Empresa',
    namePlaceholder: 'Seu nome',
    applicationForm: 'Formulário de Candidatura',
    required: 'Obrigatório',
    email: 'E-mail',
    emailPlaceholder: 'seu_email@exemplo.com',
    school: 'Instituição de Ensino',
    phone: 'Telefone',
    linkedin: 'Perfil LinkedIn',
    facebook: 'Perfil Facebook',
    identityProof: 'Documento de Identificação',
    identityTypes: '(Passaporte/CNH/RG)',
    identityFormats: 'Formatos aceitos: jpg Tamanho máximo: 2MB',
    instagram: 'Perfil Instagram',
    resume: 'Currículo (apenas pdf, doc e docx)',
    errorExpired: 'Ops, o link da entrevista parece ter expirado!',
    errorMessage:
      'Notamos que o link que você acessou não está mais disponível. A entrevista expirou e não foi reativada pelo empregador. Caso você acredite que a vaga ainda está ativa ou que o empregador ainda está contratando, entre em contato diretamente com o empregador.',
    contactUsBody:
      'Não foi possível acessar o link da entrevista&body=Olá, não consegui acessar o link da entrevista: {{- link }}. Diz que o link não está mais acessível. Poderia me ajudar a verificar?',
    toApplyFor: 'Para se candidatar a',
    coachingToApplyFor:
      'Para continuar com sua avaliação, envie os detalhes abaixo:',
    submitInfo: ', por favor, envie suas informações abaixo:',
    selectFile: 'Selecionar Arquivo',
    supportPDF: 'Suporta apenas PDF, doc e docx',
    dragPDF: 'Arraste seu arquivo aqui ou clique para',
    browse: 'procurar',
    your: 'Seu',
    prefilledNameNote:
      'Encontramos seu perfil associado a este e-mail, então preenchemos automaticamente. Note que você não pode editar estas informações',
    IP: {
      programOfStudy: 'Programa de Estudo',
      school: 'Instituição de Ensino',
      formTitle: 'Registro para Entrevista em Vídeo InsightPro',
      instruction1:
        '1. Após completar o registro, você pode escolher começar a entrevista em vídeo imediatamente ou completá-la depois através do link enviado por e-mail.',
      instruction2:
        '2. A entrevista inclui 8 questões comportamentais e durará aproximadamente 15 minutos.',
      instruction3:
        '3. Seu Relatório de Avaliação será enviado para o e-mail registrado em até 3 dias úteis após a conclusão da entrevista.',
      instruction4:
        '* Por favor, note que entrevistas incompletas ou inválidas não serão revisadas e, portanto, nenhum resultado de avaliação estará disponível.',
      toApplyFor: 'Para se candidatar a',
      at: 'na',
      howYouHear: 'Como você ficou sabendo deste evento?',
      indicateIndustry:
        '(Por favor, indique qual indústria e cargo(s) você tem interesse em seguir.)',
      yearOfGrad: 'Ano de Formatura',
      careerInterests: 'Interesses Profissionais',
      submitInfo: ', por favor envie suas informações abaixo:',
      linkedin: 'Rede Social - LinkedIn',
      wechat: 'Rede Social - WeChat',
      friend: 'Amigo',
      infoBooth: 'Stands Informativos',
      searchEngine: 'Buscador',
    },
    sopDocument: 'Carta de Apresentação (SOP)',
  },
  interviewSignUpSideScreen: {
    processHeadingWithWorkmapEnabled: 'Processo em 3 Etapas',
    processHeadingWithoutWorkmapEnabled: 'Processo em 2 Etapas',
    fillApplication: 'Preencher candidatura',
    completeAssessment: 'Completar avaliação',
    videoResponse: 'Gravar resposta em vídeo',
  },
  multiAttempts: {
    title: 'Ops, o link é válido para acesso único!',
    errorMessage:
      'Notamos que o link que você acessou não está mais disponível. É um link de acesso único. Se você não conseguiu enviar a resposta na tentativa anterior, entre em contato diretamente com o empregador para obter um novo link ou confirmar o recebimento de sua resposta inicial.',
    contactUsBody:
      'O link da entrevista é válido para acesso único&body=Olá, não consegui acessar o link da entrevista: {{- link }}. Diz que o link é válido para acesso único. Você poderia me ajudar a verificar?',
  },
  preQuestionReview: {
    title: 'Esta é uma gravação da questão teste',
    pleaseNote: 'Por favor, observe:',
    description1:
      'Embora este vídeo não será enviado, é assim que seu vídeo aparecerá para o ',
    description2:
      '. Certifique-se de que seu rosto esteja visível na frente da câmera e sua voz esteja clara.',
    employer: 'empregador',
    warning:
      'ATENÇÃO: Depois de iniciar a entrevista, você não poderá voltar ou reiniciar.',
    coachingWarning:
      'ATENÇÃO: Depois de iniciar a avaliação, você não poderá voltar ou reiniciar.',
    instruction1: 'Quando você iniciar a entrevista, você terá apenas',
    coachingInstruction1: 'Quando você iniciar a avaliação, você terá apenas',
    instruction2: 'para ler a pergunta e preparar sua resposta. Boa sorte!',
    prepTime: '{{prepTime}} segundos ',
    troubleShootRedirection:
      'Link de suporte para auxiliar e solucionar problemas de câmera/microfone ',
    clickLink: 'clique aqui',
  },
  interview: {
    testAgain: 'Testar novamente',
    startInterview: 'Iniciar Entrevista',
    recordResponse: 'Gravar Resposta',
    goodLuck: 'Boa sorte!',
    interview: 'valor',
    '10seconds': ' Você tem 10 segundos para responder esta pergunta.',
    allowDevices:
      'Você precisa permitir que o navegador acesse a câmera e o microfone.',
    done: 'Concluído',
    preparation: 'Preparação',
    testQuestion: 'Esta é uma questão teste',
    remainingTime: 'Tempo Restante',
    back: 'Voltar',
    checkYourVideo: 'Verifique seu vídeo',
    okayLetsProceed: 'Ok, vamos prosseguir',
    startPractice: 'Iniciar Prática',
    importantNotes: 'Notas Importantes:',
    instruction1: 'Primeiro, teste com uma pergunta prática',
    instruction2: 'Não contará como envio',
    instruction3: 'Clique em Iniciar Teste para começar',
    startTest: 'Iniciar Teste',
    alreadyApplied: 'Já se candidatou!',
    alreadyAppliedToTheJob: 'Você já se candidatou a esta vaga usando',
    clickBelowToProceed: 'Clique abaixo para prosseguir.',
    continue: 'Continuar',
    blockerHeading:
      'Você precisa ativar as permissões de microfone e vídeo para prosseguir.',
    blockerPrimaryText:
      'Para ativar as permissões de microfone e vídeo, siga os passos a seguir.',
    forChrome: 'Para Chrome:',
    chromeBlockerStep1:
      '1) No canto superior direito, clique em Mais. Configurações.',
    chromeBlockerStep2:
      '2) Clique em Privacidade e segurança Configurações do site. Câmera ou Microfone.',
    forFireFox: 'Para Firefox:',
    firefoxBlockerStep1: '1) Clique no botão menu e selecione Configurações.',
    firefoxBlockerStep2:
      '2) Clique em Privacidade & Segurança no menu esquerdo.',
    firefoxBlockerStep3: '3) Role até a seção Permissões.',
    firefoxBlockerStep4:
      '4) Clique no botão Configurações para a opção de Microfone e vídeo',
    questions: 'Pergunta',
  },
  preQuestion: {
    qtitle: 'Qual é sua cor favorita e por quê?',
    title1: 'Esta é uma',
    title2: ' pergunta prática ',
    title3: 'para testar seu vídeo e áudio.',
    notSubmitted: 'Não será adicionada ao seu envio.',
    toTest: 'para testar',
    instruction1:
      ' Quando estiver confortável com a experiência de gravação, clique',
    instruction2: 'na parte inferior para prosseguir.',
    warning:
      'ATENÇÃO: Você tem apenas 1 tentativa para esta entrevista. Por favor, use esta questão para testar sua voz e vídeo antes de iniciar a entrevista real.',
    coachingWarning:
      'ATENÇÃO: Você tem apenas 1 tentativa para esta avaliação. Por favor, use esta questão prática para testar sua voz e vídeo antes de iniciar sua avaliação real.',
    '10seconds': ' Você tem 10 segundos para responder esta pergunta.',
    allowDevices:
      'Você precisa permitir que o navegador acesse a câmera e o microfone.',
    numOfQuestions:
      'Há um total de {{numberOfQuestions}} perguntas nesta entrevista.',
    troubleShootRedirection:
      'Link de suporte para auxiliar e solucionar problemas de câmera/microfone ',
    clickLink: 'clique aqui',
  },
  recording: {
    howManySeconds:
      'Você tem {{seconds}} segundos para responder a esta pergunta.',
    recordingStatus: 'Gravando',
    preparationStatus: 'Preparação',
  },
  submitFailure: {
    ohNo: 'Oh não!',
    description: '',
    instruction1:
      'Infelizmente, não conseguimos capturar suas respostas gravadas com sucesso.',
    instruction2:
      'Por favor, reserve um momento para regravar suas respostas para estas perguntas.',
    instruction3:
      'Se continuar enfrentando dificuldades após reenviar, sinta-se à vontade para entrar em contato conosco em ',
    instruction4:
      'Você também pode regravar suas respostas para estas perguntas em outro dispositivo escaneando o código QR abaixo.',
    recordAgain: 'Gravar Novamente',
    networkStatus: 'Status da rede',
    connected: ' Conectado',
    notConnected: ' Não conectado',
    check1: 'Verifique se você ',
    check2: 'tem acesso à internet aqui.',
    reSubmit: 'Reenviar',
  },
  submittedScreen: {
    title: 'Sua resposta foi enviada!',
    thankyou:
      'Obrigado pelo seu tempo precioso para entrevistar conosco, esperamos que tenha gostado da experiência!',
    redirect1: 'Você será redirecionado em {{time}} segundos...',
    orClick: 'Ou clique ',
    here: 'aqui',
    ifNotRedirected: ' se você não for redirecionado',
    redirectToPortal: 'para fazer login / se inscrever no portal do candidato.',
    clickHere: 'Clique aqui',
  },
  submittingScreen: {
    title:
      'Estamos enviando suas respostas, por favor, não feche seu navegador...',
  },
  landing: {
    dear: 'Caro(a) {{name}},',
    hi: 'Oi {{name}},',
    previous: 'Anterior',
    next: 'Próximo',
    description:
      'Como parte da entrevista online, você deve completar uma entrevista em vídeo para o cargo de',
    coachingDescription:
      'Como parte da avaliação online, você deve completar uma entrevista em vídeo para o cargo de',
    with: 'com ',
    at: 'na',
    quietSpot:
      'Encontre um local tranquilo com boa iluminação e Wi-Fi forte para fazer sua avaliação.',
    instructions: 'Instruções',
    instruction1: 'Esta avaliação em vídeo consiste em',
    instruction1a: 'pergunta(s) e deve levar',
    instruction1b:
      'para completar, dependendo do número de perguntas que você receber.',
    instruction2: 'Você deve completar sua avaliação em uma única sessão.',
    instruction3:
      'Uma vez que você for apresentado à sua primeira pergunta, um cronômetro automático começará. Você terá um tempo de preparação de ',
    instruction3a:
      ', seguido por um limite de tempo de gravação que pode variar para cada pergunta.',
    instruction4:
      'Lembre-se de acompanhar o cronômetro enquanto se prepara ou grava sua resposta. Se você se sentir pronto antes do limite de tempo, pode optar por começar a gravar suas respostas ou enviá-las antecipadamente.',
    instruction5:
      'Por favor, note: Não atualize, feche ou saia da página uma vez que a entrevista tenha começado, pois você não poderá acessar o link da entrevista novamente.',
    coachingInstruction5:
      'Por favor, note: Não atualize, feche ou saia da página uma vez que a avaliação tenha começado, pois você não poderá acessar o link da avaliação novamente.',
    instruction6:
      'Não se preocupe, você poderá revisar suas respostas em vídeo no final de toda a avaliação. Se sentir que suas respostas não estão satisfatórias, você tem a opção de refazer toda a avaliação',
    instructionRedo:
      '* No entanto, a ordem e a natureza das perguntas estão sujeitas a alterações para garantir que você pareça natural e espontâneo durante a entrevista em vídeo.',
    envCheck: 'Verificação de Equipamento/Ambiente',
    env1: 'Encontre um local tranquilo com boa iluminação e Wi-Fi forte para fazer sua avaliação.',
    env2: 'A gravação da entrevista só funciona no Chrome e Firefox no momento. Certifique-se de estar usando um desses navegadores para fazer sua avaliação.',
    env3: 'Para prosseguir com a entrevista em vídeo, precisaremos de acesso à sua câmera de vídeo e ao seu microfone.',
    coachingEnv3:
      'Para prosseguir com a avaliação em vídeo, precisaremos de acesso à sua câmera de vídeo e ao seu microfone.',
    env4: 'Você verá uma pergunta prática onde poderá testar a qualidade do áudio e do vídeo antes de iniciar a avaliação real.',
    env5: 'Certifique-se de se posicionar bem dentro do quadro da tela, sente-se ereto e olhe diretamente para a câmera ao apresentar suas respostas.',
    env6: 'Você poderá revisar suas respostas em vídeo no final de toda a avaliação. Se sentir que suas respostas não estão satisfatórias, você tem a opção de refazer toda a avaliação.',
    readyClick:
      'Clique no botão "Prosseguir" sempre que estiver pronto. Boa sorte!',
    resumeOnly: 'Currículo (pdf, doc e docx apenas) *',
    error: 'Por favor, envie apenas arquivos pdf. Arquivos rejeitados',
    env7: 'Não alterne ou mude entre seus navegadores enquanto grava.',
    env8: 'Mantenha contato visual e não se distraia muito enquanto grava suas respostas.',
    env9: 'O áudio está sendo captado enquanto você grava. Certifique-se de que você é a única pessoa falando durante a gravação da entrevista.',
    instruction7:
      'Por favor, envie os documentos de identificação corretos conforme exigido (ID do governo, etc.)',
  },
  resumeParser: {
    title: 'Demo do Parser de Currículo',
    description:
      'Envie um currículo abaixo para ver como nosso sistema analisa e avalia o currículo do candidato',
    dragResume:
      'Arraste e solte um currículo aqui, ou clique para selecionar o arquivo (apenas pdf)',
    parsedResults: 'Resultados analisados',
    experience: 'Experiência',
    personalDetails: 'Detalhes Pessoais',
    totalYoE: 'Total de Anos de Experiência',
    company: 'Empresa',
    position: 'Cargo',
    duration: 'Duração',
    skills: 'Habilidades',
    education: 'Educação',
    institute: 'Instituto',
    degree: 'Grau',
    major: 'Curso',
    score: 'Pontuação',
    overall: 'Geral',
    betterFitNote:
      'Nota: Candidatos com uma pontuação geral de currículo de 55-100 são mais adequados com base na descrição do trabalho e nos requisitos atribuídos.',
    name: 'Nome',
    email: 'Email',
    phone: 'Telefone',
  },
  countdown: {
    recordIn: 'Gravar em',
  },
  prepTimer: {
    timer: 'Tempo de preparação',
  },
  candidateDetails: {
    back: 'Voltar',
    download: 'Baixar',
    questions: 'Perguntas',
    videoTranscript: 'Transcrição do Vídeo',
    resumeScore: 'Pontuação do Currículo',
    workmapScore: 'Pontuação do Workmap',
    aiVideoScore: 'Pontuação de Vídeo AI',
    videoAssessment: 'Avaliação de Entrevista em Vídeo',
    text: 'Texto',
    voice: 'Voz',
    facialExpression: 'Expressão Facial',
    professionalism: 'Profissionalismo',
    energyLevel: 'Níveis de Energia',
    communication: 'Comunicação',
    sociability: 'Sociabilidade',
    pace: 'Ritmo',
    charity: 'Clareza',
    sentiments: 'Sentimento',
    energy: 'Energia',
    positivity: 'Positividade',
    positiveEmotion: 'Emoção Positiva',
    aboutThisAssessmentReport: 'Sobre Este Relatório de Avaliação',
    introduction: 'Introdução',
    useOfReport: 'Uso do Relatório',
    confidentialityDisclaimer:
      'Confidencialidade e Isenção de Responsabilidade',
    workmapAssessment: 'Avaliação do Workmap',
    actualScore: 'Pontuações Reais',
    resumeScoring: 'Pontuação do Currículo',
    excellent: 'Excelente',
    high: 'Alto',
    medium: 'Médio',
    low: 'Baixo',
    glossaryIndex: 'Glossário/Índice',
    definitionOfWorkMapSkill: 'Definição de Habilidade do Workmap',
    definitionOfVideoInterviewParameter:
      'Definição de Parâmetros da Entrevista em Vídeo',
    candidateSummary: 'Resumo do Candidato',
    notAvailable: 'Não Disponível',
  },
  errors: {
    '404_CANDIDATE_DATA_NOT_FOUND':
      'Por favor, complete sua entrevista em vídeo para visualizar as avaliações.',
    '404_ASSESSMENT_DATA_NOT_READY':
      'Sua entrevista em vídeo está sendo avaliada no momento. Por favor, volte novamente em breve!',
    '404_CANDIDATE_NOT_AVAILABLE':
      'Você ainda não fez nenhuma entrevista em vídeo. Por favor, volte após enviar sua resposta de entrevista em vídeo.',
    default: 'Ocorreu um erro. Por favor, tente novamente mais tarde.',
  },
  companyDetails: {
    company: 'Empresa',
    about: 'Sobre',
    experience: 'Experiência',
    apply: 'Aplicar',
    description: 'Descrição',
    viewJobDetailText: 'Ver Detalhes do Trabalho',
    readMore: 'Leia mais',
    readLess: 'Leia menos',
  },
  dashboardPanel: {
    dashboard: 'Painel',
    hey: 'Olá',
    morningGreeting: 'Bom Dia',
    afternoonGreeting: 'Boa Tarde',
    eveningGreeting: 'Boa Noite',
    upgrade: 'Atualizar',
    support: 'Suporte',
    signOut: 'Sair',
    collapse: 'Recolher',
    faqs: 'Perguntas Frequentes',
    company: 'Empresa',
    role: 'Cargo',
    status: 'Status',
    action: 'Ação',
    date: 'Data',
    recordNow: 'Gravar Agora',
    responded: 'Respondido',
    processing: 'Processando',
    viewDetails: 'Ver detalhes',
    personalInfo: 'Informações Pessoais',
    introVideo: 'Vídeo de Introdução',
    score: 'Pontuação',
    email: 'E-mail',
    phone: 'Telefone',
    summary: 'Resumo',
    topSkill: 'Principais Habilidades',
    workmapScore: 'Pontuação do Mapa de Trabalho',
    aiVideoScore: 'Pontuação do Vídeo IA',
    introText1: 'Prepare-se para insights interessantes. Comece gravando',
    introText2: 'seu vídeo de introdução.',
    recordVideo: 'Gravar Vídeo',
    settings: 'Configurações',
    yourInterviews: 'Suas Entrevistas',
  },
  candidatePortalSettings: {
    settings: 'Configurações',
    publicProfile: 'Perfil Público',
    profileVisibility: 'Seu perfil está visível para todos.',
    closeAccount: 'Encerrar Conta',
    primaryConfirmationText:
      'Lamentamos vê-lo partir. Tem certeza que deseja encerrar sua conta?',
    secondaryConfirmationText:
      'Isso apagará todos os seus dados e qualquer entrevista simulada que você tenha respondido. Por favor, note que isso não cancelará seu interesse em qualquer candidatura que você tenha feito.',
    reasoningHeading: 'Nos diga por que você está encerrando sua conta:',
    primaryFeedbackText:
      'Valorizamos seu feedback, mas observe que não podemos responder a comentários sobre sua conta enviados neste formulário. Se você tiver uma dúvida ou solicitação sobre sua conta que necessite atenção, entre em contato conosco.',
    clickHere: 'Clique Aqui',
    buttonSectionPrimarytext:
      'Por favor, confirme o encerramento da sua conta?',
    deleteButtonText: 'Sim, eu quero',
    cancelButtonText: 'Agora Não',
    modalConfirmtext:
      'Esta ação levará à exclusão/retirada de qualquer candidatura que você fez no passado usando nossa plataforma. Confirme para prosseguir.',
    confirm: 'Confirmar',
  },
  purchaseBanner: {
    heading: 'Atualize para ver seu relatório detalhado da entrevista com IA',
    subHeading: 'Tenha confiança em sua próxima rodada de entrevistas.',
    launchAt: 'Oferta de lançamento por',
    only: 'Apenas',
    year: 'ano',
  },
  additionalFormField: {
    chooseFileBtn: 'Escolher arquivo',
    pleaseChooseAFileText: 'Por favor, escolha um arquivo',
  },
  domainQuestion: {
    domainAssessment: 'Avaliação de Domínio',
    of: 'de',
    submit: 'Enviar',
    questions: 'questões',
    answers: 'respostas',
    submittedAssessment: 'Você enviou a avaliação de domínio.',
    autoRedirect:
      'Redirecionando automaticamente para o próximo passo em 3 segundos...',
  },
  quitInterviewModal: {
    confirmQuit: 'Confirmar Saída da Entrevista',
    areYouSure: 'Tem certeza que deseja sair da entrevista?',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
  },
  processingVideo: {
    instruction1: 'Processando seu vídeo...',
    instruction2: 'Por favor, aguarde',
    instruction3: 'Vai levar apenas alguns segundos...',
    instruction4: 'Seja paciente.',
    noAudioFound:
      'Nenhum áudio encontrado, por favor grave novamente com áudio claro. Verifique a conexão do seu microfone.',
  },
  audioError: {
    message:
      'Lamentamos, houve um erro ao transcrever seu vídeo. Por favor, verifique seu áudio e tente novamente.',
  },
  reject: {
    thanksForInterest: 'Obrigado pelo seu interesse.',
    notQualified: 'Infelizmente, você não se qualifica para avançar nesta aplicação.',
    bestWishes: 'Desejamos tudo de bom para você.',
  },
};
