export default {
  helloApplicant: 'مرحبًا مقدم الطلب،',
  thankYou: 'شكرًا لك.',
  question: 'سؤال',
  start: 'يبدأ',
  submitting: 'تقديم',
  submit: 'يُقدِّم',
  sending: 'إرسال',
  proceed: 'يتابع',
  close: 'يغلق',
  poweredBy: 'مدعوم من',
  contactUs: 'اتصل بنا',
  showMore: 'عرض المزيد',
  showLess: 'عرض أقل',
  applying: 'التقديم',
  apply: 'يتقدم',
  click: 'انقر',
  done: 'منتهي',
  uploading: 'جارٍ التحميل',
  cantFindPage: 'لا يمكننا العثور على الصفحة التي تبحث عنها.',
  usefulLinks: 'فيما يلي بعض الروابط التي قد تكون مفيدة',
  menuHome: 'بيت',
  menuAboutUs: 'معلومات عنا',
  menuTnC: 'الشروط والأحكام',
  menuPrivacy: 'خصوصية',
  tnc1: 'من خلال تحديد هذا المربع، فإنك توافق على ',
  tnc3: 'من خلال تحديد هذا المربع، فإنك توافق على ',
  tnc2: 'Interviewer.AI شروط الاستخدام للمرشح',
  amInterested: 'أنا مهتم',
  second: 'ثانية',
  second_plural: 'ثواني',
  secondWithCount: '{{count}} ثانية',
  secondWithCount_plural: '{{count}} ثواني',
  minute: 'دقيقة',
  minute_plural: 'دقائق',
  minuteWithCount: '{{count}} دقيقة',
  minuteWithCount_plural: '{{count}} دقائق',
  workmapScreen: {
    greeting: 'عزيزي {{name}},',
    unansweredQuestions: 'أسئلتك التي لم تتم الإجابة عليها هي:',
    description:
      'أنت مدعو للمشاركة في تقييم WorkMap (المهارات) لدور {{jobName}} مع {{companyName}}.',
    instruction: 'تعليمات',
    instruction1:
      'يرجى الرد على كل عبارة بناءً على حالتك خلال معظم وقتك في العمل.',
    instruction2:
      'عند الرد على العبارات، حدد الخيار الذي يعكس طريقة تفكيرك أو شعورك بشكل عام.',
    instruction3:
      'لا توجد إجابات صحيحة أو خاطئة في هذا التقييم، لذلك لا تقضي الكثير من الوقت في أي إجابة واحدة.',
    instruction4:
      'كن عفويًا وحاول ألا تجيب بناءً على ما تعتقد أننا نريد سماعه.',
    instruction5: 'كن صادقا في ردودك. ',
    instruction6: 'يجب أن يستغرق هذا التقييم حوالي 5 دقائق لإكماله. ',
    goBack: 'عُد',
    skillsAssessment: 'تقييم المهارات',
    of: 'ل',
    answers: 'الإجابات',
    questions: 'أسئلة',
    submit: 'يُقدِّم',
    start: 'يبدأ',
    instruction7: 'لا يزال لديك أسئلة دون إجابة. ',
    stronglyDisagree: 'لا أوافق بشدة',
    disagree: 'لا أوافق',
    somewhatDisagree: 'غير موافق إلى حد ما',
    neitherAgreeNorDisagree: 'لا نتفق ولا نختلف',
    somewhatAgree: 'موافق إلى حد ما',
    agree: 'يوافق',
    stronglyAgree: 'أوافق بشدة',
    aiaInstruction1: '',
    aiaInstruction2: '',
    aiaInstruction3: '',
  },
  scale: {
    'Not at all': 'مُطْلَقاً',
    Rarely: 'نادرًا',
    Sometimes: 'أحيانا',
    Often: 'غالباً',
    'Very Often': 'في كثير من الأحيان',
    'Strongly Disagree': 'لا أوافق بشدة',
    'Somewhat Disagree': 'غير موافق إلى حد ما',
    Disagree: 'لا أوافق',
    'Neither Agree nor Disagree': 'لا نتفق ولا نختلف',
    Neutral: 'حيادي',
    Agree: 'يوافق',
    'Somewhat Agree': 'موافق إلى حد ما',
    'Strongly Agree': 'أوافق بشدة',
  },
  applySendSuccessful: {
    thankyou: 'شكرا لك على تقديمك!',
    instruction:
      'لقد أرسلنا لك بريدًا إلكترونيًا يحتوي على رابط إلى صفحة المقابلة. ',
    instruction1: '',
    instruction2: '',
    instruction3: '',
  },
  applySuccessful: {
    congrats: 'يا، {{name}}!',
    submitted: 'شكرا لك على المعلومات المقدمة.',
    whatsNext: 'ما الجديد؟',
    instruction:
      'الخطوة التالية في عملية التقديم هي تسجيل إجابات الفيديو على أسئلة المقابلة.',
    coachingInstruction:
      'كجزء من التقييم عبر الإنترنت، يُطلب منك تسجيل إجابة (إجابات) فيديو على سؤال (أسئلة) التقييم',
    instruction1: 'اختر مكانًا هادئًا وتأكد من وضوح الصوت والإضاءة',
    instruction2: 'تحقق من وجود أي مشكلات تتعلق بالاتصال قبل البدء',
    instruction3: 'كن واثقًا وبصوت عالٍ بما يكفي حتى يتم التقاط الصوت بشكل جيد',
    instruction4: 'يجب إكمال المقابلات عبر الفيديو خلال فترة زمنية معقولة. ',
    instruction5: 'ونحن نتطلع إلى ردود الفيديو الخاصة بك. ',
    giveBestShot:
      'هذه هي فرصتك لعرض شخصيتك وشغفك وطاقتك، لذا امنحها أفضل ما لديك!',
    helpfulTips: 'نصائح مفيدة:',
    doWithin48: 'افعل ذلك خلال 48 ساعة - أرسل لي الرابط',
    wsgClientInstruction1:
      '5. يرجى ملاحظة أن WSG ستقوم بفحص جميع مقاطع الفيديو المسجلة قبل تقديمها إلى أصحاب العمل. ',
    doNow: 'ابدأ الآن',
    infoProvide: 'شكرا لك على المعلومات المقدمة',
    opportunityText:
      'هذه هي فرصتك لعرض شخصيتك وشغفك وطاقتك، لذا امنحها أفضل ما لديك!',
    quietSpot: 'العثور على مساحة جيدة',
    strongNetwork: 'شبكة قوية',
    confidentLoud: 'تحدث بوضوح',
    videoCompleted: 'التقديمات',
  },
  applySuccessfulDelayInterview: {
    thankyou: 'شكرًا {{name}}!',
    submitted: 'لقد تم تقديم طلبك.',
    instruction:
      'سيقوم فريقنا بمراجعة طلبك وسيتواصل معك قريبًا إذا تم إدراجك في القائمة المختصرة للجولة التالية. ',
    goodLuck: 'أتمنى لك حظا سعيدا!',
  },
  candidateProfile: {
    intro: 'قدم نفسك',
    instruction:
      ' قبل إجراء مقابلة الفيديو، نطلب منك تخصيص بعض الوقت لتقديم نفسك.',
    placeholder: 'أخبرنا عن نفسك',
  },
  browser: {
    worksBest:
      'يعمل Interviewer.AI بشكل أفضل في Chrome وFirefox في الوقت الحالي.',
    bestExperience:
      'نحن نعمل على ضمان حصول الجميع على أفضل تجربة في إجراء المقابلات!',
    meantime:
      'في غضون ذلك، قم بتثبيت أحدث إصدار من Google Chrome أو Mozilla Firefox والوصول إلى نفس رابط المقابلة الخاص بالمقابلة:',
    downloadChrome: 'تنزيل كروم',
    downloadFirefox: 'تحميل فايرفوكس',
    lookForward: 'ونحن نتطلع إلى ردودكم!',
    bestRegards: 'أطيب التحيات،',
    interviewerTeam: 'مقابلة فريق الذكاء الاصطناعي',
    scanQrCode: 'مسح رمز الاستجابة السريعة للتسجيل على الهاتف المحمول',
  },
  digitalProfile: {
    interviews: 'مقابلات',
    interviewName: 'اسم المقابلة',
    status: 'حالة',
    dateResponded: 'تاريخ الرد',
    summary: 'ملخص',
    topSkills: 'أعلى المهارات',
    viewResume: 'عرض السيرة الذاتية',
    visitLinkedin: 'قم بزيارة لينكد إن',
    myWorkmapScore: 'نقاط خريطة العمل الخاصة بي',
  },
  organisationIntroVideoScreen: {
    continue: 'يكمل',
    note: '(ملاحظة: سيتم تفعيل زر المتابعة بعد مشاهدة الفيديو كاملاً)',
  },
  endedScreen: {
    recordAgain: 'سجل مرة أخرى',
    recordAgainQuestion: 'هل أنت متأكد أنك تريد التسجيل مرة أخرى؟',
    recordAgainDescription:
      'لاحظ أن هذا سيتطلب منك إعادة إجراء المقابلة بأكملها من السؤال 1. اضغط على الزر "تسجيل مرة أخرى" لإعادة التشغيل، أو يمكنك إغلاق هذه النافذة والمتابعة لإرسال إجاباتك.',
    thankyou: 'شكرًا {{name}}! ',
    coachingThankyou: 'شكرًا {{name}}! ',
    instruction1: 'يمكنك مراجعة ردودك أعلاه من قبل',
    instruction2: ' إرسالها مع الزر الموجود في أسفل الصفحة.',
    getFeedback: 'لمساعدتنا على تحسين منتجاتنا، نحن نقدر تعليقاتك أدناه! ',
    feedback: 'تعليق',
    placeholder: 'ملاحظاتك محل تقدير كبير!',
    rateExp: 'قيم تجربة المقابلة الخاصة بك',
    Hi: 'يا!',
    checkVideo: 'دعونا نتحقق من الفيديو الخاص بك.',
    question: 'أسئلة',
    submit: 'يُقدِّم',
  },
  thankyouEndScreen: {
    radioText1: 'غير مهتم بالوظيفة',
    radioText2: 'غير مهتم بمقابلة الفيديو',
    radioText3: 'سوف تفعل ذلك لاحقا',
    radioText4: 'غير قادر على تقديم',
    radioText5: 'آحرون',
    pleaseChooseReason: 'الرجاء اختيار السبب',
    stateYourReason: 'يرجى ذكر السبب الخاص بك',
    notFinished: 'أنت لم تنته من المقابلة.',
    notSubmittedVideoResp: 'لم يتم إرسال رد الفيديو الخاص بك.',
    noSubmittion: 'لا يوجد تقديم!',
    shareYourFeedback: 'يرجى مشاركة ملاحظاتك.',
    tellUsQuittingInterview:
      'أخبرنا إذا كنت ستترك المقابلة أو تواجه أي تحديات.',
    ratingStarsMandatory: 'تصنيف النجوم إلزامي',
    pleaseDontLeaveField: 'من فضلك لا تترك هذا الحقل فارغا',
    congratulations: 'تهانينا',
    responseSent: 'لقد تم إرسال ردود الفيديو الخاصة بك بنجاح.',
    instruction1:
      'انتظر حتى تحصل على رسالة إرسال ناجحة حيث قد يكون طلبك قيد المعالجة',
    instruction2: 'نحن نبذل قصارى جهدنا لتقييم ردك في دقائق. ',
    instruction3: 'ستتلقى بريدًا إلكترونيًا بمجرد تقييمه. ',
    instruction4:
      'يمكنك التواصل معنا على support@interviewer.ai إذا كانت لديك أي أسئلة إضافية. ',
    finishedInterview: 'لقد انتهيت من المقابلة.',
    shareExperience: 'شارك تجربة التسجيل الخاصة بك.',
    submit: 'يُقدِّم',
    thankyou: 'شكرًا لك',
    feedbackText: 'لمساعدتنا على تحسين منتجاتنا، نحن نقدر تعليقاتك أدناه! ',
    feedback: 'تعليق',
  },
  interviewCompleted: {
    submitted: 'لقد قدمت ردا على المقابلة.',
    thankyou: 'شكرا لردكم! ',
  },
  interviewFailure: {
    errorMessage:
      'لاحظنا أن الرابط الذي قمت بالدخول إليه غير صالح أو غير موجود. ',
    title: 'عفوًا، يبدو أن رابط المقابلة غير صالح!',
    contactUsBody:
      'لم أتمكن من الوصول إلى رابط مقابلة الفيديو&body=مرحبًا، لم أتمكن من الوصول إلى رابط المقابلة: {{- link }}. ',
  },
  interviewSignUp: {
    name: 'اسم',
    apply: 'تقدم بطلبك الآن',
    submit: 'يُقدِّم',
    experience: 'خبرة',
    seniorityLevel: 'مستوى الأقدمية',
    employmentType: 'نوع التوظيف',
    roleOverview: 'ملخص',
    companyName: 'شركة',
    namePlaceholder: 'اسمك',
    applicationForm: 'نموذج الطلب',
    required: 'مطلوب',
    email: 'بريد إلكتروني',
    emailPlaceholder: 'your_email@example.com',
    school: 'مدرسة',
    phone: 'هاتف',
    linkedin: 'الملف الشخصي على لينكدإن',
    facebook: 'الفيسبوك الشخصي',
    identityProof: 'تحديد الدليل',
    identityTypes: '(جواز السفر/رخصة القيادة/الهوية الصادرة عن الحكومة)',
    identityFormats:
      'التنسيقات المقبولة: jpg الحد الأقصى لحجم الملف: 2 ميجابايت',
    instagram: 'ملف تعريف انستقرام',
    resume: 'السيرة الذاتية (pdf وdoc وdocx فقط)',
    errorExpired: 'عفوًا، يبدو أن رابط المقابلة منتهي الصلاحية!',
    errorMessage: 'لقد لاحظنا أن الرابط الذي قمت بالوصول إليه لم يعد متاحًا. ',
    contactUsBody:
      'لم أتمكن من الوصول إلى رابط مقابلة الفيديو&body=مرحبًا، لم أتمكن من الوصول إلى رابط المقابلة: {{- link }}. ',
    toApplyFor: 'لتقديم طلب للحصول على',
    coachingToApplyFor: 'لمتابعة تقييمك، يرجى تقديم التفاصيل أدناه:',
    submitInfo: ' ، يرجى تقديم المعلومات الخاصة بك أدناه:',
    selectFile: 'حدد ملف',
    supportPDF: 'دعم ملفات PDF وdoc وdocx فقط',
    dragPDF: 'اسحب ملفك هنا أو انقر ل',
    browse: 'تصفح',
    your: 'لك',
    prefilledNameNote:
      'لقد وجدنا ملفك الشخصي مرتبطًا بهذا البريد الإلكتروني، لذلك قمنا بملئه مسبقًا لك. ',
    IP: {
      programOfStudy: 'برنامج الدراسة',
      school: 'مدرسة',
      formTitle: 'InsightPro تسجيل مقابلة فيديو وهمية عبر الإنترنت',
      instruction1:
        '1. عند الانتهاء من التسجيل، يمكنك اختيار إما بدء المقابلة المرئية فورًا أو إكمالها لاحقًا من خلال الرابط المقدم عبر البريد الإلكتروني.',
      instruction2: '2. تتضمن المقابلة 8 أسئلة سلوكية وتستمر حوالي 15 دقيقة.',
      instruction3:
        '3. سيتم تسليم تقرير تقييم المقابلة الخاص بك إلى البريد الإلكتروني المسجل خلال 3 أيام عمل بعد الانتهاء من المقابلة.',
      instruction4:
        '* يرجى ملاحظة أنه لن تتم مراجعة المقابلات غير المكتملة أو غير الصالحة وبالتالي لن تتوفر نتائج التقييم.',
      toApplyFor: 'لتقديم طلب للحصول على',
      at: 'في',
      howYouHear: 'كيف سمعت عن هذا الحدث؟',
      indicateIndustry:
        '(يُرجى الإشارة إلى الصناعة والمنصب (المناصب) التي ترغب في متابعتها.)',
      yearOfGrad: 'سنة التخرج',
      careerInterests: 'الاهتمامات المهنية',
      submitInfo: ' ، يرجى تقديم المعلومات الخاصة بك أدناه:',
      linkedin: 'وسائل التواصل الاجتماعي - لينكدإن',
      wechat: 'وسائل التواصل الاجتماعي - وي شات',
      friend: 'صديق',
      infoBooth: 'أكشاك المعلومات',
      searchEngine: 'محرك البحث',
    },
    sopDocument: 'وثيقة البيان الشخصي (SOP).',
  },
  interviewSignUpSideScreen: {
    processHeadingWithWorkmapEnabled: 'عملية من 3 خطوات',
    processHeadingWithoutWorkmapEnabled: 'عملية من خطوتين',
    fillApplication: 'ملء الطلب',
    completeAssessment: 'التقييم الكامل',
    videoResponse: 'تسجيل استجابة الفيديو',
  },
  multiAttempts: {
    title: 'أوه لا، الرابط صالح للوصول لمرة واحدة فقط!',
    errorMessage: 'لقد لاحظنا أن الرابط الذي قمت بالوصول إليه لم يعد متاحًا. ',
    contactUsBody:
      'رابط المقابلة صالح للدخول لمرة واحدة فقط&body=مرحبًا، لم أتمكن من الوصول إلى رابط المقابلة: {{- link }}. ',
  },
  preQuestionReview: {
    title: 'وهذا تسجيل لسؤال الاختبار',
    pleaseNote: 'يرجى الملاحظة:',
    description1:
      'على الرغم من أنه لن يتم إرسال هذا الفيديو، إلا أن هذه هي الطريقة التي سيظهر بها الفيديو الخاص بك ',
    description2: '. ',
    employer: 'صاحب العمل',
    warning: 'ملاحظة: بمجرد بدء المقابلة، لن تتمكن من العودة أو إعادة التشغيل.',
    coachingWarning:
      'ملاحظة: بمجرد بدء التقييم، لن تتمكن من العودة أو إعادة التشغيل.',
    instruction1: 'عندما تبدأ المقابلة، لديك فقط',
    coachingInstruction1: 'عند البدء في التقييم، لديك فقط',
    instruction2: 'لقراءة السؤال والاستعداد لإجابتك. ',
    prepTime: '{{prepTime}} ثواني ',
    troubleShootRedirection:
      'رابط الدعم للمساعدة واستكشاف مشكلات الكاميرا/الميكروفون وإصلاحها ',
    clickLink: 'انقر هنا',
  },
  interview: {
    testAgain: 'اختبار مرة أخرى',
    startInterview: 'ابدأ المقابلة',
    recordResponse: 'سجل الاستجابة',
    goodLuck: 'حظ سعيد!',
    interview: 'قيمة',
    '10seconds': ' لديك 10 ثوان للإجابة على هذا السؤال.',
    allowDevices: 'تحتاج إلى السماح للمتصفح بالوصول إلى الكاميرا والميكروفون.',
    done: 'منتهي',
    preparation: 'تحضير',
    testQuestion: 'هذا سؤال اختبار',
    remainingTime: 'الوقت المتبقي',
    back: 'خلف',
    checkYourVideo: 'تحقق من الفيديو الخاص بك',
    okayLetsProceed: 'حسنا، دعونا المضي قدما',
    startPractice: 'ابدأ التدريب',
    importantNotes: 'ملاحظات هامة:',
    instruction1: 'أولاً، الاختبار باستخدام سؤال تدريبي',
    instruction2: 'لن يتم احتسابه كإرسال',
    instruction3: 'انقر فوق "بدء الاختبار" للبدء',
    startTest: 'ابدأ الاختبار',
    alreadyApplied: 'تم تطبيقه بالفعل!',
    alreadyAppliedToTheJob: 'لقد تقدمت بالفعل لهذه الوظيفة باستخدام',
    clickBelowToProceed: 'انقر أدناه للمتابعة.',
    continue: 'يكمل',
    blockerHeading: 'تحتاج إلى تشغيل أذونات الميكروفون والفيديو للمضي قدمًا.',
    blockerPrimaryText:
      'لتشغيل أذونات الميكروفون والفيديو، يرجى اتباع الخطوات التالية.',
    forChrome: 'للكروم:',
    chromeBlockerStep1: '1) في الجزء العلوي الأيسر، انقر على المزيد. ',
    chromeBlockerStep2: '2) انقر فوق إعدادات موقع الخصوصية والأمان. ',
    forFireFox: 'لمتصفح فايرفوكس:',
    firefoxBlockerStep1: '1) انقر فوق زر القائمة وحدد الإعدادات.',
    firefoxBlockerStep2: '2) انقر على الخصوصية والأمان من القائمة اليسرى.',
    firefoxBlockerStep3: '3) قم بالتمرير لأسفل إلى قسم الأذونات.',
    firefoxBlockerStep4: '4) انقر فوق زر الإعدادات لخيار الميكروفون والفيديو',
    questions: 'سؤال',
  },
  preQuestion: {
    qtitle: 'أخبرنا عن لونك المفضل ولماذا؟',
    title1: 'هذا هو',
    title2: ' سؤال الممارسة ',
    title3: 'لاختبار الفيديو والصوت الخاص بك.',
    notSubmitted: 'لن تتم إضافته إلى طلبك.',
    toTest: 'للاختبار',
    instruction1: ' بمجرد أن تشعر بالارتياح تجاه تجربة التسجيل، انقر فوق',
    instruction2: 'في الأسفل للمضي قدما.',
    warning: 'ملاحظة: لديك محاولة واحدة فقط لهذه المقابلة. ',
    coachingWarning: 'ملاحظة: لديك محاولة واحدة فقط لهذا التقييم الفعلي. ',
    '10seconds': ' لديك 10 ثوان للإجابة على هذا السؤال.',
    allowDevices: 'تحتاج إلى السماح للمتصفح بالوصول إلى الكاميرا والميكروفون.',
    numOfQuestions:
      'هناك ما مجموعه {{numberOfQuestions}} الأسئلة في هذه المقابلة.',
    troubleShootRedirection:
      'رابط الدعم للمساعدة واستكشاف مشكلات الكاميرا/الميكروفون وإصلاحها ',
    clickLink: 'انقر هنا',
  },
  recording: {
    howManySeconds: 'لديك {{seconds}} ثواني للإجابة على هذا السؤال.',
    recordingStatus: 'تسجيل',
    preparationStatus: 'تحضير',
  },
  submitFailure: {
    ohNo: 'أوه لا!',
    description: '',
    instruction1: 'لسوء الحظ، لم نتمكن من التقاط إجاباتك المسجلة بنجاح.',
    instruction2: 'يرجى تخصيص بعض الوقت لإعادة تسجيل إجاباتك على هذه الأسئلة.',
    instruction3:
      'إذا كنت لا تزال تواجه التحديات بعد إعادة الإرسال، فلا تتردد في التواصل معنا على ',
    instruction4:
      'يمكنك أيضًا إعادة تسجيل إجاباتك على هذه الأسئلة على جهاز آخر عن طريق مسح رمز الاستجابة السريعة أدناه.',
    recordAgain: 'سجل مرة أخرى',
    networkStatus: 'حالة الشبكة',
    connected: ' متصل',
    notConnected: ' غير متصل',
    check1: 'تحقق مما إذا كنت ',
    check2: 'لديك إمكانية الوصول إلى الإنترنت هنا.',
    reSubmit: 'إعادة التقديم',
  },
  submittedScreen: {
    title: 'تم إرسال ردك!',
    thankyou:
      'شكرًا لك على وقتك الثمين لإجراء المقابلة معنا، ونأمل أن تستمتع بالتجربة!',
    redirect1: 'سيتم الآن إعادة توجيهك إلى {{time}} ثواني...',
    orClick: 'أو انقر ',
    here: 'هنا',
    ifNotRedirected: ' إذا لم تتم إعادة توجيهك',
    redirectToPortal: 'لتسجيل الدخول / الاشتراك في بوابة المرشح.',
    clickHere: 'انقر هنا',
  },
  submittingScreen: {
    title: 'نحن نرسل ردودكم، من فضلك لا تغلق المتصفح الخاص بك...',
  },
  landing: {
    dear: 'عزيزي {{name}},',
    hi: 'أهلاً {{name}},',
    previous: 'سابق',
    next: 'التالي',
    description:
      'كجزء من المقابلة عبر الإنترنت، يتعين عليك إكمال مقابلة فيديو للدور',
    coachingDescription:
      'كجزء من التقييم عبر الإنترنت، يُطلب منك إكمال مقابلة فيديو للدور',
    with: 'مع ',
    at: 'في',
    quietSpot: 'ابحث عن مكان هادئ به إضاءة كافية وواي فاي قوي لإجراء تقييمك.',
    instructions: 'تعليمات',
    instruction1: 'يتكون تقييم الفيديو هذا من',
    instruction1a: 'سؤال (أسئلة) وينبغي أن يأخذك',
    instruction1b: 'لإكمالها، اعتمادًا على عدد الأسئلة المخصصة لك.',
    instruction2: 'أنت مطالب بإكمال تقييمك خلال جلسة واحدة.',
    instruction3: 'بمجرد أن يُعرض عليك سؤالك الأول، يبدأ المؤقت الآلي.  ',
    instruction3a: '، متبوعًا بحد زمني للتسجيل قد يختلف لكل سؤال.',
    instruction4: 'تذكر أن تتابع المؤقت أثناء إعداد إجابتك أو تسجيلها. ',
    instruction5:
      'يرجى ملاحظة: لا تقم بتحديث الصفحة أو إغلاقها أو الخروج منها بمجرد بدء المقابلة، حيث لن يُسمح لك بالوصول إلى رابط المقابلة بعد الآن.',
    coachingInstruction5:
      'يرجى ملاحظة: لا تقم بتحديث الصفحة أو إغلاقها أو الخروج منها بمجرد بدء التقييم، حيث لن يُسمح لك بالوصول إلى رابط التقييم بعد الآن.',
    instruction6:
      'لا تقلق، ستتمكن من مراجعة ردود الفيديو الخاصة بك في نهاية التقييم بأكمله. ',
    instructionRedo:
      '* ومع ذلك، فإن ترتيب الأسئلة وطبيعتها عرضة للتغيير لضمان ظهورك بشكل طبيعي وعفوي أثناء مقابلة الفيديو.',
    envCheck: 'فحص المعدات/البيئة',
    env1: 'ابحث عن مكان هادئ به إضاءة كافية وواي فاي قوي لإجراء تقييمك.',
    env2: 'لا يعمل تسجيل المقابلة إلا في Chrome وFirefox في الوقت الحالي. ',
    env3: 'لمتابعة مقابلة الفيديو، سنطلب الوصول إلى كاميرا الفيديو والميكروفون الخاص بك.',
    coachingEnv3:
      'لمتابعة تقييم الفيديو، سنطلب الوصول إلى كاميرا الفيديو والميكروفون الخاصين بك.',
    env4: 'سيظهر لك سؤال تدريبي حيث يمكنك اختبار جودة الصوت والفيديو قبل بدء التقييم الفعلي.',
    env5: 'تأكد من وضع نفسك بشكل جيد داخل إطار شاشتك، والجلوس بشكل مستقيم والنظر مباشرة إلى الكاميرا عند تقديم إجاباتك.',
    env6: 'ستتمكن من مراجعة ردود الفيديو الخاصة بك في نهاية التقييم بأكمله. ',
    readyClick: 'انقر على زر "المتابعة" عندما تكون مستعدًا. ',
    resumeOnly: 'السيرة الذاتية (pdf، doc، docx فقط) *',
    error: 'يرجى تحميل ملف pdf فقط. ',
    env7: 'لا تقم بالتبديل أو التبديل بين متصفحاتك أثناء التسجيل.',
    env8: 'حافظ على التواصل البصري ولا تشتت انتباهك كثيرًا أثناء تسجيل إجاباتك.',
    env9: 'يتم التقاط الصوت أثناء التسجيل. ',
    instruction7:
      'يرجى تحميل وثائق الهوية الصحيحة كما هو مطلوب (الهوية الحكومية وما إلى ذلك)',
  },
  resumeParser: {
    title: 'استئناف العرض التوضيحي للمحلل',
    description:
      'قم بتحميل السيرة الذاتية أدناه لترى كيف يقوم نظامنا بتحليل وتقييم السيرة الذاتية للمرشح',
    dragResume:
      'قم بسحب وإسقاط السيرة الذاتية هنا، أو انقر لتحديد الملف (pdf فقط)',
    parsedResults: 'النتائج التي تم تحليلها',
    experience: 'خبرة',
    personalDetails: 'التفاصيل الشخصية',
    totalYoE: 'إجمالي سنوات الخبرة',
    company: 'شركة',
    position: 'موضع',
    duration: 'مدة',
    skills: 'مهارات',
    education: 'تعليم',
    institute: 'معهد',
    degree: 'درجة',
    major: 'رئيسي',
    score: 'نتيجة',
    overall: 'إجمالي',
    betterFitNote:
      'ملحوظة: المرشحون الحاصلون على مجموع إجمالي في السيرة الذاتية من 55 إلى 100 هم أكثر ملاءمة بناءً على الوصف الوظيفي والمتطلبات المعينة.',
    name: 'اسم',
    email: 'بريد إلكتروني',
    phone: 'هاتف',
  },
  countdown: {
    recordIn: 'سجل في',
  },
  prepTimer: {
    timer: 'وقت التحضير',
  },
  candidateDetails: {
    back: 'خلف',
    download: 'تحميل',
    questions: 'أسئلة',
    videoTranscript: 'نسخة الفيديو',
    resumeScore: 'استئناف النتيجة',
    workmapScore: 'نقاط خريطة العمل',
    aiVideoScore: 'نقاط فيديو الذكاء الاصطناعي',
    videoAssessment: 'تقييم مقابلة الفيديو',
    text: 'نص',
    voice: 'صوت',
    facialExpression: 'تعبيرات الوجه',
    professionalism: 'الاحتراف',
    energyLevel: 'مستويات الطاقة',
    communication: 'تواصل',
    sociability: 'مؤانسة',
    pace: 'خطوة',
    charity: 'الوضوح',
    sentiments: 'المشاعر',
    energy: 'طاقة',
    positivity: 'الإيجابية',
    positiveEmotion: 'العاطفة الإيجابية',
    aboutThisAssessmentReport: 'حول تقرير التقييم هذا',
    introduction: 'مقدمة',
    useOfReport: 'استخدام التقرير',
    confidentialityDisclaimer: 'السرية وإخلاء المسؤولية',
    workmapAssessment: 'تقييم خريطة العمل',
    actualScore: 'النتائج الفعلية',
    resumeScoring: 'استئناف التسجيل',
    excellent: 'ممتاز',
    high: 'عالي',
    medium: 'واسطة',
    low: 'قليل',
    glossaryIndex: 'مسرد / فهرس',
    definitionOfWorkMapSkill: 'تعريف مهارة خريطة العمل',
    definitionOfVideoInterviewParameter: 'تعريف معلمات مقابلة الفيديو',
    candidateSummary: 'ملخص المرشح',
    notAvailable: 'غير متوفر',
  },
  errors: {
    '404_CANDIDATE_DATA_NOT_FOUND':
      'يرجى إكمال مقابلة الفيديو الخاصة بك لعرض التقييمات.',
    '404_ASSESSMENT_DATA_NOT_READY':
      'يتم حاليًا تقييم مقابلة الفيديو الخاصة بك. ',
    '404_CANDIDATE_NOT_AVAILABLE': 'لم تقم بإجراء أي مقابلات فيديو بعد. ',
    default: 'لقد حدث خطأ. ',
  },
  companyDetails: {
    company: 'شركة',
    about: 'عن',
    experience: 'خبرة',
    apply: 'يتقدم',
    description: 'وصف',
    viewJobDetailText: 'عرض تفاصيل الوظيفة',
    readMore: 'اقرأ المزيد',
    readLess: 'اقرأ أقل',
  },
  dashboardPanel: {
    dashboard: 'لوحة القيادة',
    hey: 'يا',
    morningGreeting: 'صباح الخير',
    afternoonGreeting: 'مساء الخير',
    eveningGreeting: 'مساء الخير',
    upgrade: 'يرقي',
    support: 'يدعم',
    signOut: 'تسجيل الخروج',
    collapse: 'ينهار',
    faqs: 'الأسئلة الشائعة',
    company: 'شركة',
    role: 'دور',
    status: 'حالة',
    action: 'فعل',
    date: 'تاريخ',
    recordNow: 'سجل الآن',
    responded: 'استجاب',
    processing: 'يعالج',
    viewDetails: 'عرض التفاصيل',
    personalInfo: 'المعلومات الشخصية',
    introVideo: 'فيديو مقدمة',
    score: 'نتيجة',
    email: 'بريد إلكتروني',
    phone: 'هاتف',
    summary: 'ملخص',
    topSkill: 'أعلى المهارات',
    workmapScore: 'نقاط خريطة العمل',
    aiVideoScore: 'نقاط فيديو الذكاء الاصطناعي',
    introText1: 'استعد للحصول على رؤى مثيرة. ',
    introText2: 'الفيديو التقديمي الخاص بك.',
    recordVideo: 'سجل الفيديو',
    settings: 'إعدادات',
    yourInterviews: 'المقابلات الخاصة بك',
  },
  candidatePortalSettings: {
    settings: 'إعدادات',
    publicProfile: 'الملف الشخصي العام',
    profileVisibility: 'ملفك الشخصي مرئي للجميع.',
    closeAccount: ' إغلاق الحساب',
    primaryConfirmationText: 'نحن نأسف لرؤيتك تذهب. ',
    secondaryConfirmationText:
      'سيؤدي هذا إلى مسح جميع بياناتك وأي مقابلة وهمية قد تكون استجبت لها. ',
    reasoningHeading: ' أخبرنا عن سبب إغلاق حسابك:',
    primaryFeedbackText:
      'نحن نقدر تعليقاتك، ولكن يرجى ملاحظة أننا لا نستطيع الرد على التعليقات المتعلقة بحسابك المرسلة في هذا من. ',
    clickHere: 'انقر هنا',
    buttonSectionPrimarytext: 'يرجى تأكيد إغلاق حسابك؟',
    deleteButtonText: 'نعم أنا',
    cancelButtonText: 'ليس الآن',
    modalConfirmtext:
      'سيؤدي هذا الإجراء إلى حذف/سحب أي طلب قمت بتقديمه في الماضي باستخدام منصتنا. ',
    confirm: ' يتأكد',
  },
  purchaseBanner: {
    heading:
      'قم بالترقية لعرض تقرير المقابلة المفصل الخاص بك باستخدام الذكاء الاصطناعي',
    subHeading: 'كن واثقًا في جولتك القادمة من المقابلات.',
    launchAt: 'إطلاق العرض في',
    only: 'فقط',
    year: 'سنة',
  },
  additionalFormField: {
    chooseFileBtn: 'اختر الملف',
    pleaseChooseAFileText: 'الرجاء اختيار ملف',
  },
  domainQuestion: {
    domainAssessment: 'تقييم المجال',
    of: 'ل',
    submit: 'يُقدِّم',
    questions: 'أسئلة',
    answers: 'إجابات',
    submittedAssessment: 'لقد قدمت تقييم المجال.',
    autoRedirect: ' إعادة التوجيه تلقائيًا إلى الخطوة التالية خلال 3 ثوانٍ...',
  },
  quitInterviewModal: {
    confirmQuit: 'تأكيد إنهاء المقابلة',
    areYouSure: 'هل أنت متأكد أنك تريد إنهاء المقابلة؟',
    confirm: 'يتأكد',
    cancel: 'يلغي',
  },
  processingVideo: {
    instruction1: 'جارٍ معالجة الفيديو...',
    instruction2: 'انتظر من فضلك',
    instruction3: 'لن يستغرق الأمر سوى ثواني معدودة..',
    instruction4: 'كن صبوراً.',
    noAudioFound: 'لم يتم العثور على صوت، يرجى إعادة التسجيل بصوت واضح. ',
  },
  audioError: {
    message: 'نأسف لحدوث خطأ أثناء نسخ الفيديو الخاص بك. ',
  },
  reject: {
    thanksForInterest: 'شكرًا لاهتمامك.',
    notQualified: 'للأسف، أنت لا تستوفي الشروط للتقدم في هذه الطلب.',
    bestWishes: 'نتمنى لك كل التوفيق.',
  },
};
