import React, { useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { useTranslation, withTranslation } from 'react-i18next';

import styles from '../css/AppDrawer.module.scss';
import { ReactComponent as HomeIcon } from '../assets/HomeIcon.svg';
import { ReactComponent as SignOut } from '../assets/SignOut.svg';
import { ReactComponent as SettingsIcon } from '../assets/SettingsLogo.svg';
import { ReactComponent as UpgradeIcon } from '../assets/UpgradeIcon.svg';
import { ReactComponent as FaqIcon } from '../assets/FaqIcon.svg';
import { ReactComponent as HeadPhoneIcon } from '../assets/HeadPhoneIcon.svg';
import { ReactComponent as InterviewerAiIcon } from '../assets/InterviewerAIicon.svg';
import Auth from '../auth/auth';
import CandidateActions from '../actions/CandidateActions';

const AppDrawer = () => {
  const [isCollapsedNav, setIsCollapsedNavBar] = useState(false);
  const { t } = useTranslation();

  const handleAppDrawerCollapsed = () => {
    setIsCollapsedNavBar(!isCollapsedNav);
    CandidateActions.setAppDrawerOpen(isCollapsedNav);
  };

  const closeNavBar = () => {
    CandidateActions.setIsNavigationDrawerOpen(false);
  };

  return (
    <div>
      <div className={
        isCollapsedNav ? styles.appDrawerContainerCollapsed : styles.appDrawerContainerOpen
      }
      >
        <div className={styles.menuContainer}>
          <div className={styles.brandContainer}>
            <InterviewerAiIcon width="32" height="39" />
          </div>
          <div className={styles.brandMobileContainer}>
            {/* <IaiNameAndIcon /> */}
            <InterviewerAiIcon width="32" height="39" />
          </div>

          <nav
            className={classnames(styles.navContainer, 'navContainer')}
          >
            <div>
              <div
                onClick={closeNavBar}
                onKeyDown={closeNavBar}
                tabIndex={0}
                role="button"
                type="button"
              >
                <NavLink to="/candidate">
                  <div className={styles.navItem}>
                    <div className={classnames(styles.menyIcon, 'menyIcon')}>
                      <HomeIcon width="22" height="23" />
                    </div>
                    <span>{t('dashboardPanel.dashboard')}</span>
                  </div>
                </NavLink>
              </div>
              <NavLink to="/user/billing" style={{ display: 'none' }}>
                <div className={styles.navItem}>
                  <div className={classnames(styles.menyIcon, 'menyIcon')}>
                    <UpgradeIcon width="22" height="23" />
                  </div>
                  <span>{t('dashboardPanel.upgrade')}</span>
                </div>
              </NavLink>
              <div
                onClick={closeNavBar}
                onKeyDown={closeNavBar}
                tabIndex={0}
                role="button"
                type="button"
              >
                <NavLink to="/user/settings">
                  <div className={styles.navItem}>
                    <div className={classnames(styles.menyIcon, 'menyIcon')}>
                      <SettingsIcon width="23" height="22" />
                    </div>
                    <span>{t('dashboardPanel.settings')}</span>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className={styles.navItemsBottom}>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://interviewer.ai/help/faqs/"
              >
                <div className={styles.navItem}>
                  <div className={classnames(styles.menyIcon, 'menyIcon')}>
                    <FaqIcon width="22" height="23" />
                  </div>
                  <span>{t('dashboardPanel.faqs')}</span>
                </div>
              </a>

              <a href="mailto:support@interviewer.ai">
                <div className={styles.navItem}>
                  <div className={classnames(styles.menyIcon, 'menyIcon')}>
                    <HeadPhoneIcon width="22" height="23" />
                  </div>
                  <span>{t('dashboardPanel.support')}</span>
                </div>
              </a>

              {
                Auth.isAuthenticated() && (
                  <button
                    type="button"
                    onClick={() => Auth.signOut()}
                    className={styles.signOutButton}
                  >

                    <div className="menyIcon">
                      <SignOut width="22" height="23" />
                    </div>
                    <span>{t('dashboardPanel.signOut')}</span>

                  </button>

                )
              }


              <button
                type="button"
                className={styles.transparentButton}
                onClick={handleAppDrawerCollapsed}
              >
                <div className="menyIcon">
                  {
                    isCollapsedNav ? (
                      <HiChevronRight
                        className="glyphicon"
                        fill="#5F6981"
                      />
                    ) : (
                      <HiChevronLeft
                        className="glyphicon"
                        fill="#5F6981"
                      />
                    )
                  }
                </div>
                {
                  !isCollapsedNav && (
                    <span>{t('dashboardPanel.collapse')}</span>
                  )
                }
              </button>
            </div>

          </nav>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(withRouter(AppDrawer));
