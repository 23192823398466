export default {
  helloApplicant: 'Bonjour M. le candidat,',
  thankYou: 'Je vous remercie de votre attention.',
  question: 'Question',
  start: 'Début',
  submitting: 'Soumission',
  submit: 'Soumettre',
  sending: 'Envoyer',
  proceed: 'Procéder',
  close: 'Fermer',
  poweredBy: 'Réalisé par',
  contactUs: 'Nous contacter',
  showMore: 'Afficher plus',
  showLess: 'Montrer moins',
  applying: 'Postuler',
  apply: 'Postuler',
  click: 'Cliquer',
  done: 'Terminé',
  uploading: 'Télécharger',
  cantFindPage: 'Nous ne trouvons pas la page que vous recherchez.',
  usefulLinks: 'Voici quelques liens qui pourraient vous être utiles',
  menuHome: 'Accueil',
  menuAboutUs: 'À propos de nous',
  menuTnC: 'Conditions d\'utilisation',
  menuPrivacy: 'Protection de la vie privée',
  tnc1: 'En postulant, vous acceptez les ',
  tnc3: 'En postulant, vous acceptez les ',
  tnc2: 'Conditions d\'utilisation pour les candidats d\'Interviewer.AI',
  amInterested: 'Je suis intéressé(e) par',
  second: 'seconde',
  second_plural: 'secondes',
  secondWithCount: '{{count}} secondes',
  secondWithCount_plural: '{{count}} secondes',
  minute: 'minute',
  minute_plural: 'minutes',
  minuteWithCount: '{{count}} minute',
  minuteWithCount_plural: '{{count}} minutes',
  workmapScreen: {
    greeting: 'Cher {{name}},',
    unansweredQuestions: 'Vos questions sans réponse sont :',
    description: 'Vous êtes invité(e) à participer à une évaluation WorkMap(Skill) pour le rôle de {{jobName}} au sein de {{companyName}}.',
    instruction: 'Instruction',
    instruction1: 'Veuillez répondre à chaque affirmation en vous basant sur votre attitude au travail la plupart du temps.',
    instruction2: 'Lorsque vous répondez aux affirmations, choisissez l\'option qui correspond à ce que vous pensez ou ressentez généralement.',
    instruction3: 'Il n\'y a pas de bonnes ou de mauvaises réponses dans cette évaluation, ne passez donc pas trop de temps sur une seule réponse.',
    instruction4: 'Soyez spontané et essayez de ne pas répondre en fonction de ce que vous pensez que nous voulons entendre.',
    instruction5: 'Soyez honnête dans vos réponses. Vos réponses influenceront la précision de votre évaluation.',
    instruction6: 'Cette évaluation devrait prendre environ 5 minutes. Cliquez sur le bouton « Démarrer » lorsque vous êtes prêt. Tous nos vœux de réussite !',
    goBack: 'Retourner en arrière',
    skillsAssessment: 'Évaluation des compétences',
    of: 'de',
    answers: 'Réponses',
    questions: 'questions',
    submit: 'Soumettre',
    start: 'Démarrer',
    instruction7: 'Vous n\'avez pas encore répondu à certaines questions. Veuillez répondre à toutes les questions avant de soumettre votre demande.',
    stronglyDisagree: 'Pas du tout d\'accord',
    disagree: 'Pas d\'accord',
    somewhatDisagree: 'Plutôt pas d\'accord',
    neitherAgreeNorDisagree: 'Ni d\'accord ni en désaccord',
    somewhatAgree: 'Plutôt d\'accord',
    agree: 'D\'accord',
    stronglyAgree: 'Tout à fait d\'accord',
  },
  scale: {
    'Not at all': 'Pas du tout',
    Rarely: 'Rarement',
    Sometimes: 'Parfois',
    Often: 'Souvent',
    'Very Often': 'Très souvent',
    'Strongly Disagree': 'Pas du tout d\'accord',
    'Somewhat Disagree': 'Plutôt pas d\'accord',
    Disagree: 'Pas d\'accord',
    'Neither Agree nor Disagree': 'Ni d\'accord ni en désaccord',
    Neutral: 'Neutre',
    Agree: 'D\'accord',
    'Somewhat Agree': 'Plutôt d\'accord',
    'Strongly Agree': 'Tout à fait d\'accord',
  },
  applySendSuccessful: {
    thankyou: 'Merci pour votre candidature !',
    instruction: 'Nous vous avons envoyé un courriel contenant le lien vers la page d\'entretien. Nous vous recommandons d\'enregistrer dans les 48 heures pour maximiser vos chances d\'être invité au prochain tour. Nous vous souhaitons bonne chance !',
  },
  applySuccessful: {
    congrats: 'Bonjour, {{name}} !',
    submitted: 'Merci pour les informations fournies.',
    whatsNext: 'Quelles sont les nouveautés ?',
    instruction: 'La prochaine étape de votre processus de candidature consiste à enregistrer des réponses vidéo aux questions de l\'entretien.',
    coachingInstruction: 'Dans le cadre de l\'évaluation en ligne, vous devez enregistrer une ou plusieurs réponses vidéo aux questions d\'évaluation suivantes',
    instruction1: '1. Choisissez un endroit calme et veillez à ce qu\'il y ait suffisamment de lumière autour de vous',
    instruction2: '2. Maintenez une bonne connectivité réseau',
    instruction3: '3. Soyez confiant et parlez suffisamment fort pour que l\'on puisse vous entendre clairement.',
    instruction4: '4. Les vidéos peuvent être complétées à tout moment avant la date d\'expiration.',
    instruction5: 'Nous attendons avec impatience vos réponses vidéo. Nous vous souhaitons bonne chance !',
    giveBestShot: 'C\'est l\'occasion pour vous de montrer votre personnalité, votre passion et votre énergie, alors donnez le meilleur de vous-même !',
    helpfulTips: 'Conseils utiles :',
    doWithin48: 'Faites-le dans les 48 heures - envoyez-moi le lien.',
    wsgClientInstruction1:
      '5. Veuillez noter que WSG filtrera toutes les vidéos enregistrées avant de les soumettre aux employeurs. Les vidéos qui contiennent ou montrent un comportement inapproprié ou non professionnel, tel que l\'utilisation de mots offensants ou d\'un arrière-plan inapproprié, ne seront pas soumises aux employeurs pour le traitement de leur(s) demande(s) d\'emploi. Les candidats seront invités à revoir leur vidéo enregistrée et à la soumettre à nouveau.',
    doNow: 'Commencer maintenant',
    infoProvide: 'Merci pour les informations fournies',
    opportunityText: 'C\'est l\'occasion de mettre en valeur votre personnalité, votre passion et votre énergie, alors faites de votre mieux !',
    quietSpot: 'Endroit calme et assuré',
    strongNetwork: 'Un réseau solide',
    confidentLoud: 'Confiant et assez fort',
    videoCompleted: 'Les vidéos peuvent être complétées',
  },
  applySuccessfulDelayInterview: {
    thankyou: 'Merci {{name}} !',
    submitted: 'Votre candidature a été envoyée.',
    instruction: 'Notre équipe examinera votre candidature et vous contactera sous peu si vous êtes présélectionné pour le prochain cycle. Notez que seuls les candidats présélectionnés seront informés.',
    goodLuck: 'Nous vous souhaitons bonne chance !',
  },
  candidateProfile: {
    intro: 'Présentez-vous',
    instruction: ' Avant de passer l\'entretien vidéo, nous vous demandons de prendre un moment pour vous présenter.',
    placeholder: 'Parlez-nous de vous',
  },
  browser: {
    worksBest: 'Interviewer.AI fonctionne mieux sur Chrome et Firefox pour le moment.',
    bestExperience: 'Nous travaillons pour nous assurer que tout le monde a la meilleure expérience possible lors des entretiens !',
    meantime: 'In the meantime, install the latest version of Google Chrome or Mozilla Firefox and access the same interview link for your interview:',
    downloadChrome: 'Télécharger Chrome',
    downloadFirefox: 'Télécharger Firefox',
    lookForward: 'Nous sommes impatients de recevoir vos réponses !',
    bestRegards: 'Cordialement,',
    interviewerTeam: 'L\'équipe d\'Interviewer.AI',
    scanQrCode: 'Scanner le code QR pour enregistrer sur mobile.',
  },
  digitalProfile: {
    interviews: 'Interviews',
    interviewName: 'Nom de l\'entretien',
    status: 'Statut',
    dateResponded: 'Date de réponse',
    summary: 'Résumé',
    topSkills: 'Compétences principales',
    viewResume: 'Voir le CV',
    visitLinkedin: 'Consulter Linkedin',
    myWorkmapScore: 'Mon score sur Workmap',
  },
  organisationIntroVideoScreen: {
    continue: 'continuer',
    note: '(Remarque : Le bouton Continuer sera activé après avoir regardé la vidéo complète)',
  },
  endedScreen: {
    recordAgain: 'Réenregistrer',
    recordAgainQuestion: 'Êtes-vous sûr de vouloir enregistrer à nouveau ?',
    recordAgainDescription: 'Notez qu\'il vous faudra alors recommencer l\'ensemble de l\'entretien à partir de la question 1. Appuyez sur le bouton Enregistrer à nouveau pour recommencer, ou vous pouvez fermer cette fenêtre et soumettre vos réponses.',
    thankyou: 'Merci {{name}} ! Vous avez terminé l\'entretien.',
    coachingThankyou: 'Merci {{name}} ! Vous avez terminé l\'évaluation.',
    instruction1: 'Vous pouvez revoir vos réponses ci-dessus avant de les',
    instruction2: ' avant de les soumettre à l\'aide du bouton situé au bas de la page.',
    getFeedback: 'Pour nous aider à améliorer notre produit, nous vous remercions de nous faire part de vos commentaires ci-dessous ! Que pensez-vous de l\'expérience de l\'enregistrement ?',
    feedback: 'Vos réactions',
    placeholder: 'Vos commentaires sont très appréciés !',
    rateExp: 'Évaluez votre expérience de l\'entretien',
    Hi: 'Hé !',
    checkVideo: 'Jetons un coup d\'œil à votre vidéo.',
    question: 'Questions',
    submit: 'Soumettre',
  },
  thankyouEndScreen: {
    radioText1: 'Pas intéressé par le poste',
    radioText2: 'Pas intéressé par l\'interview vidéo',
    radioText3: 'Je le ferai plus tard',
    radioText4: 'Impossible de soumettre',
    radioText5: 'Autres',
    pleaseChooseReason: 'Veuillez choisir une raison',
    stateYourReason: 'Veuillez indiquer votre raison',
    notFinished: 'Vous n\'avez pas terminé l\'entretien.',
    notSubmittedVideoResp: 'Votre réponse vidéo n\'a pas été soumise.',
    noSubmittion: 'Aucune soumission !',
    shareYourFeedback: 'S\'il vous plaît partagez vos commentaires.',
    tellUsQuittingInterview: 'Dites-nous si vous quittez l\'entretien ou si vous rencontrez des difficultés.',
    ratingStarsMandatory: 'Les étoiles de notation sont obligatoires',
    pleaseDontLeaveField: 'Merci de ne pas laisser ce champ vide',
    congratulations: 'Félicitations',
    responseSent: 'Vos réponses vidéo ont été envoyées avec succès.',
    instruction1: 'Attendez de recevoir un message indiquant que votre demande a été envoyée avec succès, car il se peut que votre demande soit en cours de traitement',
    instruction2: 'Nous faisons de notre mieux pour évaluer votre réponse en quelques minutes. Toutefois, cela peut prendre entre 30 minutes et 2 heures. Veuillez prévoir un certain temps pour le traitement de votre demande.',
    instruction3: 'Vous recevrez un courriel une fois que votre candidature aura été évaluée. Vérifiez votre dossier spam au cas où vous ne recevriez pas d\'e-mail dans les deux heures.',
    instruction4: 'Vous pouvez nous contacter à l\'adresse support@interviewer.ai pour toute question supplémentaire. Bon entretien !',
    finishedInterview: 'Vous avez terminé l\'entretien.',
    shareExperience: 'Partagez votre expérience de l\'enregistrement.',
    submit: 'Soumettre',
    thankyou: 'Remerciez-nous',
    feedbackText: 'Pour nous aider à améliorer notre produit, nous vous remercions de nous faire part de vos commentaires ci-dessous ! Que pensez-vous de l\'expérience de l\'enregistrement ?',
    feedback: 'Retour d\'information',
  },
  interviewCompleted: {
    submitted: 'Vous avez répondu à l\'interview.',
    thankyou: 'Nous vous remercions pour votre réponse ! Si vous avez des questions, n\'hésitez pas à nous contacter :)',
  },
  interviewFailure: {
    errorMessage: 'Nous constatons que le lien auquel vous avez accédé n\'est pas valide ou n\'existe pas. Si vous avez reçu ce lien d\'un employeur, veuillez le contacter directement pour obtenir un nouveau lien.',
    title: 'Oups, le lien de l\'entretien semble être invalide !',
    contactUsBody: 'Impossible d\'accéder au lien de l\'entretien vidéo&body=Hi, je n\'ai pas pu accéder au lien de l\'entretien : {{- link }}. Le lien semble avoir été fermé par l\'employeur. Pourriez-vous m\'aider à y jeter un coup d\'œil ?',
  },
  interviewSignUp: {
    name: 'Nom',
    apply: 'Postuler maintenant',
    submit: 'Soumettre',
    experience: 'Expérience',
    seniorityLevel: 'Niveau d\'ancienneté',
    employmentType: 'Type d\'emploi',
    roleOverview: 'Aperçu du rôle',
    companyName: 'L\'entreprise',
    namePlaceholder: 'Votre nom',
    applicationForm: 'Formulaire de candidature',
    required: 'Obligatoire',
    email: 'Courriel',
    emailPlaceholder: 'your_email@example.com',
    school: 'École',
    phone: 'Téléphone',
    linkedin: 'Profil LinkedIn',
    resume: 'CV (pdf, doc et docx uniquement)',
    errorExpired: 'Oups, le lien de l\'entretien semble avoir expiré !',
    errorMessage: 'Nous constatons que le lien auquel vous avez accédé n\'est plus disponible. L\'entretien a expiré et n\'a pas été réactivé par l\'employeur. Si vous pensez que le poste est toujours actif ou que l\'employeur recrute encore, veuillez contacter l\'employeur directement.',
    contactUsBody: 'Could not access video interview link&body=Hi, I could not access the interview link : {{- link}}. Il est indiqué que le lien de l\'entretien n\'est plus accessible. Pourriez-vous m\'aider à y jeter un coup d\'œil ?',
    toApplyFor: 'Pour postuler',
    coachingToApplyFor: 'Pour poursuivre votre évaluation, veuillez fournir les informations ci-dessous :',
    submitInfo: ' , Pour poursuivre l\'évaluation, veuillez fournir les informations ci-dessous :, veuillez fournir les informations ci-dessous :',
    selectFile: 'Sélectionner un fichier',
    supportPDF: 'Supporte PDF, doc et docx uniquement)',
    dragPDF: 'Faites glisser votre fichier ici ou cliquez pour',
    browse: 'parcourir',
    your: 'Votre programme d\'études',
    IP: {
      programOfStudy: 'programme d\'études',
      school: 'L\'école',
      formTitle: 'Inscription à l\'entretien vidéo fictif en ligne InsightPro',
      instruction1: '1. Une fois l\'inscription terminée, vous pouvez choisir de commencer l\'entretien vidéo immédiatement ou de le terminer plus tard grâce au lien fourni par e-mail.',
      instruction2: '2. L\'entretien comprend 8 questions comportementales et dure environ 15 minutes.',
      instruction3: '3. Votre rapport d\'évaluation de l\'entretien sera envoyé à l\'adresse électronique enregistrée dans les 3 jours ouvrables suivant la fin de l\'entretien.',
      instruction4: '* Veuillez noter que les entretiens incomplets ou invalides ne seront pas examinés et qu\'aucun résultat d\'évaluation ne sera donc disponible.',
      toApplyFor: 'Pour postuler',
      at: 'à',
      howYouHear: 'Comment avez-vous entendu parler de cet événement ?',
      indicateIndustry: '(Veuillez indiquer le secteur d\'activité et le(s) poste(s) que vous souhaitez occuper.',
      yearOfGrad: 'Année d\'obtention du diplôme',
      careerInterests: 'Intérêts professionnels',
      submitInfo: 'Si vous n\'avez pas d\'informations sur votre carrière, veuillez les indiquer ci-dessous :',
      linkedin: 'Médias sociaux - LinkedIn',
      wechat: 'Médias sociaux - WeChat',
      friend: 'Ami(e)',
      infoBooth: 'Kiosques d\'information',
      searchEngine: 'Moteur de recherche',
    },
  },
  interviewSignUpSideScreen: {
    processHeadingWithWorkmapEnabled: 'Processus en 3 étapes',
    processHeadingWithoutWorkmapEnabled: 'Processus en 2 étapes',
    fillApplication: 'Remplir le formulaire',
    completeAssessment: 'Compléter l\'évaluation',
    videoResponse: 'Enregistrer la réponse vidéo',
  },
  multiAttempts: {
    title: 'Oh non, le lien n\'est valable que pour un accès unique !',
    errorMessage: 'Nous constatons que le lien auquel vous avez accédé n\'est plus disponible. Il s\'agit d\'un lien d\'accès unique, si vous n\'avez pas pu soumettre la réponse lors de la première tentative. Veuillez contacter l\'employeur directement pour obtenir un nouveau lien ou pour confirmer la réception de votre réponse initiale.',
    contactUsBody: 'Le lien d\'entretien est valable pour un accès unique&body=Hi, I could not access the interview link : {{- link}}. Il est indiqué que le lien n\'est valable que pour un accès unique. Pourriez-vous m\'aider à y jeter un coup d\'œil ?',
  },
  preQuestionReview: {
    title: 'Il s\'agit d\'un enregistrement de la question du test',
    pleaseNote: 'Veuillez noter que cette vidéo ne sera pas soumise :',
    description1: 'Bien que cette vidéo ne soit pas soumise, c\'est ainsi que votre vidéo apparaîtra à',
    description2: '. Veillez à ce que votre visage soit visible devant la caméra et que votre voix soit claire.',
    employer: 'employeur',
    warning: 'REMARQUE : une fois que vous avez commencé l\'entretien, vous ne pourrez plus revenir en arrière ou recommencer.',
    coachingWarning: 'REMARQUE : une fois l\'évaluation commencée, vous ne pourrez plus revenir en arrière ni recommencer.',
    instruction1: 'Lorsque vous commencez l\'entretien, vous avez seulement',
    coachingInstruction1: 'Lorsque vous commencez l\'évaluation, vous n\'avez qu\'à',
    instruction2: 'de lire la question et de préparer votre réponse. Bonne chance !',
    prepTime: '{{prepTime}} secondes ',
    troubleShootRedirection: 'Lien d\'assistance pour résoudre les problèmes de caméra/micro',
    clickLink: 'cliquez ici',
  },
  interview: {
    testAgain: 'Refaire le test',
    startInterview: 'Commencer l\'entretien',
    recordResponse: 'Enregistrer la réponse',
    goodLuck: 'Bonne chance !',
    interview: 'valeur',
    testQuestion: 'This is a test question',
    '10seconds': ' Vous avez 10 secondes pour répondre à cette question.',
    allowDevices: 'Vous devez autoriser le navigateur à accéder à la caméra et au microphone.',
    done: 'Fait',
    preparation: 'Préparation',
    remainingTime: 'Temps restant',
    back: 'Retour',
    checkYourVideo: 'Vérifiez votre vidéo',
    okayLetsProceed: 'Ok, continuons',
    startPractice: 'Commencer l\'entraînement',
    importantNotes: 'Remarques importantes :',
    instruction1: 'Testez d\'abord avec une question d\'entraînement',
    instruction2: 'Ce test ne sera pas considéré comme une soumission.',
    instruction3: 'Cliquez sur Démarrer le test pour commencer',
    startTest: 'Commencer le test',
    alreadyApplied: 'J\'ai déjà postulé !',
    alreadyAppliedToTheJob: 'Vous avez déjà postulé à cet emploi en utilisant',
    clickBelowToProceed: 'Cliquez ci-dessous pour continuer.',
    continue: 'Continuer',
    blockerHeading: 'Vous devez activer votre microphone et les autorisations vidéo pour avancer.',
    blockerPrimaryText: 'Pour activer les autorisations du microphone et de la vidéo, veuillez suivre les étapes suivantes.',
    forChrome: 'Pour Chrome :',
    chromeBlockerStep1: '1) En haut à droite, cliquez sur Plus. Paramètres.',
    chromeBlockerStep2: '2) Cliquez sur Confidentialité et sécurité Paramètres du site. Caméra ou microphone.',
    forFireFox: 'Pour Firefox :',
    firefoxBlockerStep1: '1) Cliquez sur le bouton de menu et sélectionnez Paramètres.',
    firefoxBlockerStep2: '2) Cliquez sur Confidentialité et sécurité dans le menu de gauche.',
    firefoxBlockerStep3: '3) Faites défiler jusqu\'à la section Autorisations.',
    firefoxBlockerStep4: '4) Cliquez sur le bouton Paramètres pour l\'option Microphone et vidéo',
  },
  preQuestion: {
    qtitle: 'Dites-nous votre couleur préférée et pourquoi ?',
    title1: 'Il s\'agit d\'une',
    title2: ' question d\'entraînement ',
    title3: 'pour tester votre vidéo et votre audio.',
    notSubmitted: 'Elle ne sera pas ajoutée à votre envoi.',
    toTest: 'pour tester',
    instruction1: ' Une fois que vous êtes à l\'aise avec l\'enregistrement, cliquez sur',
    instruction2: 'en bas de la page pour continuer.',
    warning: 'REMARQUE : vous n\'avez droit qu\'à une seule tentative pour cet entretien. Veuillez utiliser cette question pour tester votre voix et votre vidéo avant de commencer l\'entretien proprement dit.',
    coachingWarning: 'REMARQUE : Vous n\'avez droit qu\'à une seule tentative pour cette évaluation. Veuillez utiliser cette question d\'entraînement pour tester votre voix et votre vidéo avant de commencer l\'évaluation proprement dite.',
    '10seconds': ' Vous disposez de 10 secondes pour répondre à cette question.',
    allowDevices: 'Vous devez autoriser le navigateur à accéder à la caméra et au microphone.',
    numOfQuestions: 'Il y a un total de {{numberOfQuestions}} dans cet entretien.',
    troubleShootRedirection: 'Lien d\'assistance pour résoudre les problèmes liés à la caméra et au microphone ',
    clickLink: 'cliquez ici',
  },
  recording: {
    howManySeconds: 'Vous avez {{seconds}} secondes pour répondre à cette question.',
    recordingStatus: 'Enregistrement',
    preparationStatus: 'Préparation',
  },
  submitFailure: {
    ohNo: 'Oh non !',
    description: 'Nous avons rencontré un problème lors de l\'envoi de votre réponse. Ce problème est généralement dû à une connexion réseau intermittente qui entraîne l\'interruption de la soumission. Nous vous prions de nous excuser pour ce désagrément.',
    instruction1: 'Malheureusement, nous n\'avons pas pu capturer correctement vos réponses enregistrées..',
    instruction2: 'Veuillez prendre un moment pour réenregistrer vos réponses à ces questions.',
    instruction3: 'Si vous continuez à rencontrer des difficultés après avoir soumis une nouvelle demande, n\'hésitez pas à nous contacter à ',
    instruction4: 'Vous pouvez également réenregistrer vos réponses à ces questions sur un autre appareil en scannant le qrcode ci-dessous.',
    recordAgain: 'Enregistrer à nouveau',
    networkStatus: 'État du réseau',
    connected: ' Connecté',
    notConnected: ' Non connecté',
    check1: 'Vérifiez si vous avez ',
    check2: 'si vous avez accès à l\'internet.',
    reSubmit: 'Soumettre à nouveau',
  },
  submittedScreen: {
    title: 'Votre réponse a été envoyée !',
    thankyou: 'Nous vous remercions pour le temps précieux que vous avez consacré à l\'entretien, et nous espérons que vous avez apprécié l\'expérience !',
    redirect1: 'Vous serez redirigé dans {{temps}} secondes...',
    orClick: 'Ou cliquez ',
    here: 'ici',
    ifNotRedirected: 'si vous n\'êtes pas redirigé',
    redirectToPortal: 'Pour se connecter / s\'inscrire sur le portail du candidat.',
    clickHere: 'Cliquez ici',
  },
  submittingScreen: {
    title: 'Nous sommes en train de soumettre vos réponses, veuillez ne pas fermer votre navigateur...',
  },
  landing: {
    dear: 'Cher {{name}},',
    hi: 'Bonjour {{name}},',
    previous: 'Précédent',
    next: 'Suivant',
    description: 'Dans le cadre de l\'entretien en ligne, vous devez passer un entretien vidéo pour le rôle de',
    coachingDescription: 'Dans le cadre de l\'évaluation en ligne, vous devez passer un entretien vidéo pour le rôle de',
    with: 'avec ',
    at: 'à',
    quietSpot: 'Trouvez un endroit calme, bien éclairé et doté d\'une bonne connexion WiFi pour passer votre évaluation.',
    instructions: 'Instructions',
    instruction1: 'Cette évaluation vidéo comprend',
    instruction1a: 'question(s) et devrait vous prendre',
    instruction1b: 'en fonction du nombre de questions qui vous sont attribuées.',
    instruction2: 'Vous devez effectuer votre évaluation en une seule séance.',
    instruction3: 'Dès que la première question vous est posée, un chronomètre automatique démarre. Vous disposerez d\'un temps de préparation de ',
    instruction3a: ', suivi d\'un temps d\'enregistrement qui peut varier pour chaque question.',
    instruction4: 'N\'oubliez pas de suivre le chronomètre pendant que vous préparez ou enregistrez votre réponse. Si vous êtes prêt avant la fin du temps imparti, vous pouvez choisir de commencer à enregistrer vos réponses ou de les soumettre à l\'avance.',
    instruction5: 'Remarque : ne rafraîchissez pas, ne fermez pas ou ne quittez pas la page une fois que l\'évaluation a commencé, car vous ne seriez plus autorisé à accéder au lien de l\'évaluation.',
    coachingInstruction5: 'Please Note: Do not refresh, close or exit the page once the assessment has started, as you would not be allowed to access the assessment link anymore.',
    instruction6: 'Ne vous inquiétez pas, vous pourrez revoir vos réponses vidéo à la fin de l\'évaluation. Si vous estimez que vos réponses ne sont pas satisfaisantes, vous avez la possibilité de repasser l\'ensemble de l\'évaluation',
    instructionRedo: '* Toutefois, l\'ordre et la nature des questions sont susceptibles d\'être modifiés afin que vous puissiez paraître naturel et spontané lors de l\'entretien vidéo.',
    envCheck: 'Vérification de l\'équipement et de l\'environnement',
    env1: 'Trouvez un endroit calme, bien éclairé et doté d\'une bonne connexion WiFi pour passer votre évaluation.',
    env2: 'L\'enregistrement de l\'entretien ne fonctionne pour l\'instant qu\'avec Chrome et Firefox. Assurez-vous d\'utiliser l\'un ou l\'autre de ces navigateurs pour passer votre évaluation.',
    env3: 'Pour procéder à l\'entretien vidéo, vous devez avoir accès à votre caméra vidéo et à votre microphone.',
    coachingEnv3: 'Pour procéder à l\'évaluation vidéo, vous devez avoir accès à votre caméra vidéo et à votre microphone.',
    env4: 'Une question d\'entraînement vous sera présentée afin que vous puissiez tester la qualité de votre son et de votre image avant de commencer l\'évaluation proprement dite.',
    env5: 'Veillez à bien vous placer dans le cadre de votre écran, à vous asseoir bien droit et à regarder la caméra en face lorsque vous présentez vos réponses.',
    env6: 'Vous pourrez revoir vos réponses vidéo à la fin de l\'évaluation. Si vous estimez que vos réponses ne sont pas satisfaisantes, vous avez la possibilité de repasser l\'ensemble de l\'évaluation.',
    readyClick: 'Cliquez sur le bouton « Continuer » lorsque vous êtes prêt(e). Tous nos vœux de réussite !',
    resumeOnly: 'CV (pdf, doc et docx uniquement) *',
    error: 'Veuillez télécharger un fichier pdf uniquement. Fichiers rejetés',
    env7: 'Ne basculez pas entre vos navigateurs pendant l\'enregistrement.',
    env8: 'Maintenez votre contact visuel et ne vous distrayez pas trop pendant que vous enregistrez vos réponses.',
    env9: 'L\'audio est capté pendant que vous enregistrez. Assurez-vous que vous êtes la seule personne à parler pendant l\'enregistrement de l\'entretien.',
    instruction7: 'Veuillez télécharger les bons documents d\'identification comme mandaté (carte d\'identité gouvernementale, etc.)',
  },
  resumeParser: {
    title: 'Démonstration de l\'analyseur de CV',
    description: 'Téléchargez un CV ci-dessous pour voir comment notre système analyse et évalue le CV du candidat.',
    dragResume: 'Glissez-déposez un CV ici, ou cliquez pour sélectionner un fichier (pdf uniquement)',
    parsedResults: 'Résultats analysés',
    experience: 'Expérience',
    personalDetails: 'Détails personnels',
    totalYoE: 'Nombre total d\'années d\'expérience',
    company: 'Entreprise',
    position: 'Poste occupé',
    duration: 'Durée de l\'expérience',
    skills: 'Compétences',
    education: 'Formation',
    institute: 'Institut',
    degree: 'Diplôme',
    major: 'Principale',
    score: 'Score',
    overall: 'Total',
    betterFitNote: 'Note : Les candidats dont le curriculum vitae est noté de 55 à 100 sont mieux adaptés à la description du poste et à ses exigences.',
    name: 'Nom du candidat',
    email: 'Courriel',
    phone: 'Téléphone',
  },
  countdown: {
    recordIn: 'Enregistrer dans',
  },
  prepTimer: {
    timer: 'temps de préparation',
  },
  candidateDetails: {
    back: 'Retour',
    download: 'Télécharger',
    questions: 'Questions',
    videoTranscript: 'Transcription de la vidéo',
    resumeScore: 'Score du CV',
    workmapScore: 'Score de la carte de travail',
    aiVideoScore: 'Score vidéo AI',
    videoAssessment: 'Évaluation de l\'entretien vidéo',
    text: 'Texte',
    voice: 'Voix',
    facialExpression: 'Expression faciale',
    professionalism: 'Professionnalisme',
    energyLevel: 'Niveaux d\'énergie',
    communication: 'La communication',
    sociability: 'Sociabilité',
    pace: 'Rythme',
    charity: 'Clarté',
    sentiments: 'Sentiment',
    energy: 'Énergie',
    positivity: 'Positivité',
    positiveEmotion: 'Émotion positive',
    aboutThisAssessmentReport: 'À propos de ce rapport d\'évaluation',
    introduction: 'Introduction',
    useOfReport: 'Utilisation du rapport',
    confidentialityDisclaimer: 'Confidentialité et clause de non-responsabilité',
    workmapAssessment: 'Évaluation de la carte de travail',
    actualScore: 'Scores réels',
    resumeScoring: 'Notation des CV',
    excellent: 'Excellent',
    high: 'Très bonne',
    medium: 'Moyenne',
    low: 'Faible',
    glossaryIndex: 'Glossary/Index',
    definitionOfWorkMapSkill: 'Définition des compétences de la carte de travail',
    definitionOfVideoInterviewParameter: 'Définition des paramètres de l\'entretien vidéo',
    candidateSummary: 'Résumé du candidat',
    notAvailable: 'Non disponible',

  },
  errors: {
    '404_CANDIDATE_DATA_NOT_FOUND': 'Veuillez compléter votre entretien vidéo pour voir les évaluations.',
    '404_ASSESSMENT_DATA_NOT_READY': 'Votre entretien vidéo est en cours d\'évaluation. Revenez bientôt !',
    '404_CANDIDATE_NOT_AVAILABLE': 'Vous n\'avez pas encore passé d\'entretien vidéo. Veuillez revenir après avoir soumis votre réponse à l\'entretien vidéo.',
    default: 'Une erreur s\'est produite. Veuillez réessayer plus tard.',
  },
  companyDetails: {
    company: 'Société',
    about: 'À propos de',
    experience: 'Experience',
    apply: 'Postuler',
    description: 'Description de l\'emploi',
    viewJobDetailText: 'Voir les détails de l\'emploi',
    readMore: 'Lire la suite',
    readLess: 'Lire moins',
  },
  dashboardPanel: {
    dashboard: 'Tableau de bord',
    hey: 'Bonjour',
    morningGreeting: 'Bonjour',
    afternoonGreeting: 'Bonjour',
    eveningGreeting: 'Bonsoir',
    upgrade: 'Mise à jour',
    support: 'Support',
    signOut: 'Se déconnecter',
    collapse: 'S\'effondrer',
    faqs: 'FAQs',
    company: 'L\'entreprise',
    role: 'Rôle',
    status: 'Statut',
    action: 'Action',
    date: 'Date',
    recordNow: 'Enregistrer maintenant',
    responded: 'Répondu',
    processing: 'Traitement',
    viewDetails: 'Voir les détails',
    personalInfo: 'Informations personnelles',
    introVideo: 'Vidéo d\'introduction',
    score: 'Score',
    email: 'Courriel',
    phone: 'Téléphone',
    summary: 'Résumé',
    topSkill: 'Compétences principales',
    workmapScore: 'Score de la carte de travail',
    aiVideoScore: 'Score vidéo AI',
    introText1: 'Préparez-vous à recevoir des informations passionnantes. Commencez par enregistrer',
    introText2: 'votre vidéo d\'introduction.',
    recordVideo: 'Enregistrer la vidéo',
    settings: 'Settings',
  },
  candidatePortalSettings: {
    settings: 'Settings',
    publicProfile: 'Public Profile',
    profileVisibility: 'Your profile is visible to everyone.',
    closeAccount: ' Close Account',
    primaryConfirmationText: 'we\'re sorry to see you go. Are you sure you want to close your account?',
    secondaryConfirmationText: 'This would erase all your data and any mock interview that you may have responded to. Please not this will not withdraw your interest from any application you have made.',
    reasoningHeading: ' Tell us why you\'re closing your account:',
    primaryFeedbackText: 'We value your feedback, but please note that we aren\'t able to respond to comments about your account submitedin this from. if you have a question or request about your account that requires attention please contact us.',
    clickHere: 'Click Here',
    buttonSectionPrimarytext: 'Please confirm your account close?',
    deleteButtonText: 'Yes, I\'m',
    cancelButtonText: 'Not Now',
    modalConfirmtext: 'This action will lead to deletion/withdrawal of any application you made in the past using our platform. Confirm to proceed.',
    confirm: ' Confirm',
  },
  purchaseBanner: {
    heading: 'Passez à la version supérieure pour consulter votre rapport d\'entretien détaillé sur l\'IA',
    subHeading: 'Soyez confiant lors de votre prochaine série d\'entretiens.',
    launchAt: 'Lancer l\'offre à',
    only: 'Seulement',
    year: 'an',
  },
  additionalFormField: {
    chooseFileBtn: 'Choisir un fichier',
    pleaseChooseAFileText: 'Veuillez choisir un fichier',
  },
  domainQuestion: {
    domainAssessment: 'Évaluation du domaine',
    of: 'sur',
    submit: 'Soumettre',
    questions: 'questions',
    answers: 'Réponses',
    autoRedirect: 'Redirection automatique vers l\'étape suivante dans 3 secondes...',
    submittedAssessment: 'Vous avez soumis l\'évaluation de domaine.',
  },
  quitInterviewModal: {
    confirmQuit: 'Confirmer la fin de l\'entretien',
    areYouSure: 'Etes-vous sûr de vouloir quitter l\'entretien ?',
    confirm: 'Confirmer',
    cancel: 'Annuler',
  },
  processingVideo: {
    instruction1: 'Traitement de votre vidéo...',
    instruction2: 'S\'il vous plaît, attendez',
    instruction3: 'Cela ne prendra que quelques secondes...',
    instruction4: 'Sois patient.',
    noAudioFound: 'Aucun audio trouvé, veuillez réenregistrer avec un audio clair. Veuillez vérifier la connexion de votre micro.',
  },
  audioError: {
    message: 'Nous regrettons qu\'il y ait une erreur lors de la transcription de votre vidéo. Veuillez vérifier votre audio et réessayer.',
  },
  reject: {
    thanksForInterest: 'Merci pour votre intérêt.',
    notQualified: 'Malheureusement, vous ne remplissez pas les critères pour aller plus loin dans cette candidature.',
    bestWishes: 'Nous vous souhaitons tout le meilleur.',
  },
};
