export default {
  helloApplicant: 'Xin chào Ứng viên,',
  thankYou: 'Cảm ơn.',
  question: 'Câu hỏi',
  start: 'Start',
  submitting: 'Bắt đầu',
  submit: 'Đang gửi đi',
  sending: 'Đang gửi',
  proceed: 'Tiến hành',
  close: 'Đóng',
  poweredBy: 'Được cung cấp bởi',
  contactUs: 'Liên hệ với Chúng tôi',
  showMore: 'Hiển thị Thêm',
  showLess: 'Ẩn Bớt',
  applying: 'Đang nộp đơn',
  apply: 'Nộp đơn',
  click: 'Nhấp',
  done: 'Đã xong',
  uploading: 'Đang tải lên',
  cantFindPage: 'Chúng tôi không thể tìm thấy trang bạn đang tìm kiếm.',
  usefulLinks: 'Dưới đây là một vài liên kết có thể hữu ích',
  menuHome: 'Trang chủ',
  menuAboutUs: 'Giới thiệu về Chúng tôi',
  menuTnC: 'Điều khoản và Điều kiện',
  menuPrivacy: 'Quyền riêng tư',
  tnc1: 'Bằng việc nộp đơn, bạn đồng ý với ',
  tnc3: 'Bằng việc nộp đơn, bạn đồng ý với ',
  tnc2: 'Điều khoản Sử dụng Ứng viên Interviewer.AI',
  amInterested: 'Tôi quan tâm',
  second: 'giây',
  second_plural: 'giây',
  secondWithCount: '{{count}} giây',
  secondWithCount_plural: '{{count}} giây',
  minute: 'phút',
  minute_plural: 'phút',
  minuteWithCount: '{{count}} phút',
  minuteWithCount_plural: '{{count}} phút',
  workmapScreen: {
    greeting: '{{name}} thân mến,',
    unansweredQuestions: 'Câu hỏi chưa được trả lời của bạn là:',
    description: 'Bạn được mời tham gia Bài đánh giá Workmap (Kỹ năng) cho vai trò {{jobName}} với {{companyName}}.',
    instruction: 'Hướng dẫn',
    instruction1: 'Xin vui lòng trả lời từng phát biểu dựa trên chính bản thân bạn trong hầu hết thời gian làm việc.',
    instruction2: 'Khi trả lời các phát biểu, hãy chọn tùy chọn phản ánh cách bạn nghĩ hoặc cảm nhận.',
    instruction3: 'Không có câu trả lời đúng hay sai trong bài đánh giá này, vì vậy đừng dành quá nhiều thời gian cho bất kỳ câu trả lời nào.',
    instruction4: 'Hãy thoải mái và cố gắng không trả lời dựa trên những gì bạn nghĩ chúng tôi muốn nghe.',
    instruction5: 'Hãy trả lời trung thực. Các câu trả lời của bạn sẽ ảnh hưởng đến tính chính xác của bài đánh giá.',
    instruction6: 'Sẽ mất khoảng 5 phút để hoàn thành bài đánh giá này. Nhấp vào nút "Bắt đầu" bất cứ khi nào bạn đã sẵn sàng. Chúc bạn những điều tốt đẹp nhất!',
    goBack: 'Quay trở lại',
    skillsAssessment: 'Bài đánh giá Kỹ năng',
    of: 'của',
    answers: 'Câu trả lời',
    questions: 'câu hỏi',
    submit: 'Gửi đi',
    start: 'Bắt đầu',
    instruction7: 'Vẫn còn những câu hỏi bạn chưa trả lời. Xin vui lòng hoàn thành tất cả các câu hỏi trước khi gửi đi.',
    stronglyDisagree: 'Hoàn toàn Phản đối',
    disagree: 'Phản đối',
    somewhatDisagree: 'Phản đối ở Mức nào đó',
    neitherAgreeNorDisagree: 'Không Đồng ý cũng chẳng Phản đối',
    somewhatAgree: 'Đồng ý ở Mức nào đó',
    agree: 'Đồng ý',
    stronglyAgree: 'Hoàn toàn Đồng ý',
  },
  scale: {
    'Not at all': 'Không hề',
    Rarely: 'Hiếm khi',
    Sometimes: 'Thỉnh thoảng',
    Often: 'Thường',
    'Very Often': 'Rất Thường xuyên',
    'Strongly Disagree': 'Hoàn toàn Phản đối',
    'Somewhat Disagree': 'Phản đối ở Mức nào đó',
    Disagree: 'Phản đối',
    'Neither Agree nor Disagree': 'Không Đồng ý cũng chẳng Phản đối',
    Neutral: 'Trung lập',
    Agree: 'Đồng ý',
    'Somewhat Agree': 'Đồng ý ở Mức nào đó',
    'Strongly Agree': 'Hoàn toàn Đồng ý',
  },
  applySendSuccessful: {
    thankyou: 'Xin cảm ơn bạn đã nộp đơn!',
    instruction: 'Chúng tôi đã gửi cho bạn một email có liên kết đến trang phỏng vấn. Bạn nên quay video trong vòng 48 giờ tới để tối đa hóa cơ hội được mời vào vòng tiếp theo. Chúc bạn may mắn!',
  },
  applySuccessful: {
    congrats: '{{name}} thân mến!',
    submitted: 'Xin cảm ơn bạn đã cung cấp thông tin.',
    whatsNext: 'Có gì mới?',
    instruction: 'Bước tiếp theo trong quy trình nộp đơn là quay video bạn trả lời các câu hỏi phỏng vấn.',
    coachingInstruction: 'Là một phần của bài đánh giá trực tuyến, bạn cần phải quay video (các) câu trả lời cho (các) câu hỏi của bài đánh giá',
    instruction1: '1. Chọn một nơi yên tĩnh và đảm bảo quanh bạn có đầy đủ ánh sáng',
    instruction2: '2. Duy trì kết nối mạng mạnh mẽ',
    instruction3: '3. Hãy tự tin và nói đủ lớn để chúng tôi có thể nghe rõ bạn',
    instruction4: '4. Bạn có thể hoàn thành video bất cứ lúc nào trước ngày hết hạn',
    instruction5: 'Chúng tôi rất mong nhận được video câu trả lời của bạn. Chúc bạn mọi điều may mắn!',
    giveBestShot: 'Đây là cơ hội để bạn thể hiện tính cách, niềm đam mê và năng lượng của mình, vì vậy bạn hãy cố gắng hết sức nhé!',
    helpfulTips: 'Lời khuyên Hữu ích:',
    doWithin48: 'Quay video trong vòng 48 giờ - gửi liên kết cho tôi',
    wsgClientInstruction1:
      '5. Xin lưu ý rằng WSG sẽ sàng lọc tất cả các video đã quay trước khi gửi cho nhà tuyển dụng. Các video chứa hoặc hiển thị hành vi không phù hợp hoặc không chuyên nghiệp như sử dụng các từ xúc phạm, nền không phù hợp sẽ không được gửi cho nhà tuyển dụng để xử lý (các) đơn xin việc. Ứng viên xin việc sẽ được thông báo để sửa đổi video đã quay và gửi lại.',
    doNow: 'Bắt đầu ngay',
    infoProvide: 'Cảm ơn bạn đã cung cấp thông tin',
    opportunityText: 'Đây là cơ hội để bạn thể hiện cá tính, niềm đam mê và năng lượng của mình, vì vậy hãy cố gắng hết sức!',
    quietSpot: 'Chỗ ở yên tĩnh và đảm bảo',
    strongNetwork: 'Mạng mạnh',
    confidentLoud: 'Tự tin và đủ lớn',
    videoCompleted: 'Video có thể được hoàn thành',
  },
  applySuccessfulDelayInterview: {
    thankyou: 'Cảm ơn {{name}}!',
    submitted: 'Đơn xin việc của bạn đã được gửi đi.',
    instruction: 'Đội ngũ của chúng tôi sẽ xem xét đơn xin việc của bạn và sẽ sớm liên hệ lại nếu bạn được lọt vào vòng tiếp theo. Lưu ý rằng chỉ các ứng viên lọt vào vòng tiếp theo mới nhận được thông báo.',
    goodLuck: 'Chúc bạn may mắn!',
  },
  candidateProfile: {
    intro: 'Giới thiệu bản thân',
    instruction: ' Trước khi thực hiện cuộc phỏng vấn video, chúng tôi đề nghị bạn dành một chút thời gian để giới thiệu bản thân.',
    placeholder: 'Nói cho chúng tôi biết về bạn',
  },
  browser: {
    worksBest: 'Interviewer.AI hoạt động tốt nhất trên trình duyệt Chrome và Firefox hiện tại"',
    bestExperience: 'Chúng tôi đang làm việc để đảm bảo mọi người có trải nghiệm phỏng vấn tốt nhất!',
    meantime: 'Trong khi đó, hãy cài đặt phiên bản mới nhất của Google Chrome hoặc Mozilla Firefox và truy cập vào cùng một liên kết phỏng vấn cho cuộc phỏng vấn của bạn:',
    downloadChrome: 'Tải xuống Chrome',
    downloadFirefox: 'Tải xuống Firefox',
    lookForward: 'Chúng tôi rất mong chờ nhận được phản hồi từ bạn!',
    bestRegards: 'Trân trọng,',
    interviewerTeam: 'Nhóm Interviewer.AI',
    scanQrCode: 'Quét mã QR để ghi âm trên điện thoại di động',
  },
  digitalProfile: {
    interviews: 'Cuộc phỏng vấn',
    interviewName: 'Tên Cuộc phỏng vấn',
    status: 'Trạng thái',
    dateResponded: 'Ngày Trả lời',
    summary: 'Tóm tắt',
    topSkills: 'Kỹ năng Hàng đầu',
    viewResume: 'Xem Sơ yếu lý lịch',
    visitLinkedin: 'Truy cập LinkedIn',
    myWorkmapScore: 'Điểm Workmap của Tôi',
  },
  organisationIntroVideoScreen: {
    continue: 'Lanjut',
    note: '(Lưu ý: Nút Tiếp tục sẽ được bật sau khi xem hết video)',
  },
  endedScreen: {
    recordAgain: 'Quay Lần nữa',
    recordAgainQuestion: 'Bạn có chắc bạn muốn quay video lần nữa không?',
    recordAgainDescription: 'Xin lưu ý rằng thao tác này sẽ yêu cầu bạn thực hiện lại toàn bộ cuộc phỏng vấn từ câu hỏi 1. Nhấn lại nút "Quay Lần nữa" để bắt đầu lại hoặc bạn có thể đóng cửa sổ này và tiến hành gửi câu trả lời của mình.',
    thankyou: 'Cảm ơn {{name}}! Bạn đã hoàn thành cuộc phỏng vấn.',
    coachingThankyou: 'Cảm ơn {{name}}! Bạn đã hoàn thành bài đánh giá.',
    instruction1: 'Bạn có thể xem lại câu trả lời của mình ở trên trước khi',
    instruction2: ' gửi chúng bằng nút ở dưới cùng của trang.',
    getFeedback: 'Để giúp chúng tôi cải thiện sản phẩm của mình, chúng tôi rất cảm kích phản hồi của bạn dưới đây! Bạn nghĩ gì về trải nghiệm quay video?',
    feedback: 'Phản hồi',
    placeholder: 'Chúng tôi rất cảm kích phản hồi của bạn!',
    rateExp: 'Xếp hạng trải nghiệm phỏng vấn của bạn',
    Hi: 'Xin chào!!',
    checkVideo: 'Hãy xem qua video của bạn.',
    question: 'Câu hỏi',
    submit: 'Gửi',
  },
  thankyouEndScreen: {
    radioText1: 'Không quan tâm đến công việc',
    radioText2: 'Không quan tâm đến Phỏng vấn qua video',
    radioText3: 'Sẽ làm điều đó sau',
    radioText4: 'Không thể gửi',
    radioText5: 'Người khác',
    pleaseChooseReason: 'Vui lòng chọn một lý do',
    stateYourReason: 'Hãy nêu lý do của bạn',
    notFinished: 'Bạn chưa kết thúc cuộc phỏng vấn.',
    notSubmittedVideoResp: 'Phản hồi video của bạn chưa được gửi.',
    noSubmittion: 'Không nộp !',
    shareYourFeedback: 'Hãy chia sẻ phản hồi của bạn.',
    tellUsQuittingInterview: 'Hãy cho chúng tôi biết nếu bạn muốn bỏ cuộc phỏng vấn hoặc đối mặt với bất kỳ thử thách nào.',
    ratingStarsMandatory: 'Xếp hạng sao là bắt buộc',
    pleaseDontLeaveField: 'Vui lòng không để trống trường này',
    congratulations: 'Xin chúc mừng',
    responseSent: 'Đã gửi thành công video câu trả lời của bạn.',
    instruction1: 'Xin vui lòng chờ cho đến khi bạn nhận được một thông báo gửi thành công vì đơn của bạn có thể đang được xử lý',
    instruction2: 'Chúng tôi sẽ cố gắng hết sức để đánh giá câu trả lời của bạn trong vài phút. Tuy nhiên, quá trình này có thể mất từ 30 phút đến 2 giờ. Xin vui lòng chờ một khoảng thời gian để chúng tôi xử lý.',
    instruction3: 'Bạn sẽ nhận được một email sau khi chúng tôi đã đánh giá xong. Hãy kiểm tra thư mục thư rác của bạn trong trường hợp bạn không nhận được email trong vòng 2 giờ.',
    instruction4: 'Bạn có thể liên hệ với chúng tôi theo địa chỉ support@interviewer.ai nếu có thêm bất kỳ câu hỏi nào. Chúc bạn phỏng vấn vui vẻ!',
    finishedInterview: 'Bạn đã kết thúc cuộc phỏng vấn.',
    shareExperience: 'Hãy chia sẻ trải nghiệm quay video của bạn.',
    submit: 'Gửi',
    thankyou: 'Cảm ơn',
    feedbackText: 'Để giúp chúng tôi cải thiện sản phẩm của mình, chúng tôi rất cảm kích phản hồi của bạn dưới đây! Bạn nghĩ gì về trải nghiệm quay video?',
    feedback: 'Phản hồi',
  },
  interviewCompleted: {
    submitted: 'Bạn đã gửi câu trả lời cho cuộc phỏng vấn.',
    thankyou: 'Cảm ơn câu trả lời của bạn! Nếu bạn có bất kỳ câu hỏi nào, xin đừng ngần ngại liên lạc với chúng tôi :)',
  },
  interviewFailure: {
    errorMessage: 'Chúng tôi nhận thấy rằng liên kết bạn truy cập là không hợp lệ hoặc không tồn tại. Nếu bạn đã nhận được liên kết này từ nhà tuyển dụng, xin vui lòng liên hệ trực tiếp với nhà tuyển dụng để nhận một liên kết mới.',
    title: 'Rất tiếc, liên kết phỏng vấn dường như không hợp lệ!',
    contactUsBody: 'Không thể truy cập liên kết phỏng vấn video&body=Xin chào, tôi không thể truy cập liên kết phỏng vấn: {{- link}}. Thông báo cho biết rằng dường như nhà tuyển dụng đã đóng liên kết phỏng vấn. Bạn có thể xem qua giúp tôi không?',
  },
  interviewSignUp: {
    name: 'Tên',
    apply: 'Nộp đơn Ngay',
    submit: 'Gửi',
    experience: 'Kinh nghiệm',
    seniorityLevel: 'Mức độ Thâm niên',
    employmentType: 'Loại Việc làm',
    roleOverview: 'Tổng quan về Vai trò',
    companyName: 'Công ty',
    namePlaceholder: 'Tên của bạn',
    applicationForm: 'Mẫu Đơn',
    required: 'Bắt buộc',
    email: 'Địa chỉ email',
    emailPlaceholder: 'your_email@example.com',
    school: 'Trường học',
    phone: 'Số điện thoại',
    linkedin: 'Hồ sơ LinkedIn',
    resume: 'Sơ yếu lý lịch (chỉ pdf, doc và docx)',
    errorExpired: 'Rất tiếc, liên kết phỏng vấn dường như đã hết hạn!',
    errorMessage: 'Chúng tôi nhận thấy rằng liên kết bạn truy cập không còn nữa. Cuộc phỏng vấn đã hết hạn và chưa được nhà tuyển dụng kích hoạt lại. Nếu bạn cảm thấy công việc vẫn còn hoặc nhà tuyển dụng vẫn đang tuyển người, xin vui lòng liên hệ trực tiếp với nhà tuyển dụng đó.',
    contactUsBody: 'Không thể truy cập liên kết phỏng vấn video&body= Xin chào, tôi không thể truy cập liên kết phỏng vấn: {{- link}}. Thông báo cho biết rằng liên kết phỏng vấn không còn có thể truy cập được. Bạn có thể xem qua giúp tôi không?',
    toApplyFor: 'Để nộp đơn cho',
    coachingToApplyFor: 'Để tiếp tục bài đánh giá của bạn, xin vui lòng gửi thông tin chi tiết dưới đây:',
    submitInfo: ' , xin vui lòng gửi thông tin của bạn dưới đây:',
    selectFile: 'Chọn Tệp',
    supportPDF: 'Chỉ hỗ trợ PDF, doc và docx',
    dragPDF: 'Kéo tập tin của bạn vào đây hoặc nhấp vào',
    browse: 'duyệt',
    your: 'Của bạn',
    IP: {
      programOfStudy: 'Chương trình Học',
      school: 'Trường học',
      formTitle: 'Đăng ký Phỏng vấn Video Giả lập Trực tuyến InsightPro',
      instruction1: '1. Sau khi hoàn thành quá trình đăng ký, bạn có thể chọn bắt đầu cuộc phỏng vấn video ngay lập tức hoặc hoàn thành cuộc phỏng vấn video sau đó thông qua liên kết được cung cấp qua email.',
      instruction2: '2. Cuộc phỏng vấn bao gồm 8 câu hỏi về hành vi và sẽ kéo dài khoảng 15 phút.',
      instruction3: '3. Báo cáo Đánh giá Phỏng vấn sẽ được gửi đến địa chỉ email đã đăng ký của bạn trong vòng 3 ngày làm việc sau khi bạn hoàn thành cuộc phỏng vấn.',
      instruction4: '* Xin lưu ý rằng các cuộc phỏng vấn không đầy đủ hoặc không hợp lệ sẽ không được xem xét nên sẽ không có kết quả đánh giá.',
      toApplyFor: 'Để nộp đơn cho',
      at: 'tại',
      howYouHear: 'Làm thế nào bạn biết về sự kiện này?',
      indicateIndustry: '(Xin vui lòng cho biết ngành và vị trí nào bạn muốn theo đuổi.)',
      yearOfGrad: 'Năm Tốt nghiệp',
      careerInterests: 'Đam mê Nghề nghiệp',
      submitInfo: ', xin vui lòng gửi thông tin của bạn dưới đây:',
      linkedin: 'Mạng Xã hội - LinkedIn',
      wechat: 'Mạng Xã hội - WeChat',
      friend: 'Bạn bè',
      infoBooth: 'Gian Thông tin',
      searchEngine: 'Công cụ Tìm kiếm',
    },
  },
  interviewSignUpSideScreen: {
    processHeadingWithWorkmapEnabled: 'Quy trình 3 Bước',
    processHeadingWithoutWorkmapEnabled: 'Quy trình 2 Bước',
    fillApplication: 'Điền vào đơn đăng ký',
    completeAssessment: 'Hoàn thành bài đánh giá',
    videoResponse: 'Quay video câu trả lời',
  },
  multiAttempts: {
    title: 'Rất tiếc, chỉ có thể truy cập liên kết này một lần!',
    errorMessage: 'Chúng tôi nhận thấy rằng liên kết đã bạn truy cập không còn khả dụng nữa. Đây là liên kết truy cập một lần. Nếu bạn không thể gửi câu trả lời trong nỗ lực trước đó, xin vui lòng liên hệ trực tiếp với nhà tuyển dụng để nhận liên kết mới hoặc để xác nhận rằng họ đã nhận được câu trả lời ban đầu của bạn.',
    contactUsBody: 'Chỉ có thể truy cập liên kết phỏng vấn một lần&body=Xin chào, tôi không thể truy cập liên kết phỏng vấn: {{- link}}. Thông báo cho biết rằng chỉ có thể truy cập liên kết đó một lần. Bạn có thể xem qua giúp tôi không?',
  },
  preQuestionReview: {
    title: 'Đây là bản ghi của câu hỏi kiểm thử',
    pleaseNote: 'Xin lưu ý:',
    description1: 'Mặc dù video này sẽ không được gửi đi nhưng đây là cách video của bạn sẽ hiển thị với ',
    description2: '. Xin vui lòng đảm bảo khuôn mặt của bạn hiển thị rõ trước camera và giọng nói của bạn rõ ràng.',
    employer: 'nhà tuyển dụng',
    warning: 'LƯU Ý: Sau khi bắt đầu cuộc phỏng vấn, bạn sẽ không thể quay trở lại hoặc bắt đầu lại.',
    coachingWarning: 'LƯU Ý: Sau khi bắt đầu bài đánh giá, bạn sẽ không thể quay trở lại hoặc bắt đầu lại.',
    instruction1: 'Khi bắt đầu cuộc phỏng vấn, bạn chỉ có',
    coachingInstruction1: 'Khi bắt đầu bài đánh giá, bạn chỉ có',
    instruction2: 'để đọc câu hỏi và chuẩn bị cho câu trả lời của bạn. Chúc bạn may mắn!',
    prepTime: '{{prepTime}} giây ',
    troubleShootRedirection: 'Đường link hỗ trợ để trợ giúp và khắc phục sự cố camera/micô ',
    clickLink: 'nhấp vào đây',
  },
  interview: {
    testAgain: 'Kiểm thử lần nữa',
    startInterview: 'Bắt đầu Cuộc phỏng vấn',
    recordResponse: 'Quay video Câu trả lời',
    goodLuck: 'Chúc may mắn!',
    interview: 'giá trị',
    '10seconds': ' Bạn có 10 giây để trả lời câu hỏi này.',
    allowDevices: 'Bạn cần cho phép trình duyệt truy cập camera và micrô.',
    done: 'Đã xong',
    preparation: 'Chuẩn bị',
    remainingTime: 'Thời gian Còn lại',
    back: 'Quay trở lại',
    checkYourVideo: 'Kiểm tra video của bạn',
    okayLetsProceed: 'OK, hãy tiến hành',
    startPractice: 'Bắt đầu Luyện tập',
    importantNotes: 'Lưu ý Quan trọng:',
    instruction1: 'Đầu tiên, hãy kiểm thử với một câu hỏi luyện tập',
    instruction2: 'Chúng sẽ không được tính là bài gửi',
    instruction3: 'Nhấp vào "Bắt đầu Kiểm thử" để bắt đầu',
    startTest: 'Bắt đầu Kiểm thử',
    alreadyApplied: 'Đã nộp đơn rồi!',
    alreadyAppliedToTheJob: 'Bạn đã nộp đơn cho công việc này bằng cách sử dụng',
    clickBelowToProceed: 'Nhấp vào bên dưới để tiếp tục.',
    continue: 'Tiếp tục',
    blockerHeading: 'Bạn cần bật micrô và quyền truy cập video để tiếp tục.',
    blockerPrimaryText: 'Để bật quyền micrô và video, vui lòng làm theo các bước sau.',
    forChrome: 'Đối với Chrome:',
    chromeBlockerStep1: '1) Ở trên cùng bên phải, nhấp vào Thêm. Cài đặt.',
    chromeBlockerStep2: '2) Nhấp vào Cài đặt trang web riêng tư và bảo mật. Máy ảnh hoặc Micrô.',
    forFireFox: 'For Firefox:',
    firefoxBlockerStep1: '1) Nhấp vào nút menu và chọn Cài đặt.',
    firefoxBlockerStep2: '2) Nhấp vào Quyền riêng tư & Bảo mật từ menu bên trái.',
    firefoxBlockerStep3: '3) Cuộn xuống phần Quyền.',
    firefoxBlockerStep4: '4) Nhấp vào nút Cài đặt cho tùy chọn Micrô và video',
  },
  preQuestion: {
    qtitle: 'Cho chúng tôi biết màu yêu thích của bạn là gì và tại sao?',
    title1: 'Đây là một',
    title2: ' câu hỏi luyện tập ',
    title3: 'để kiểm thử video và âm thanh của bạn.',
    notSubmitted: 'Chúng sẽ không được thêm vào bài gửi của bạn.',
    toTest: 'để kiểm thử',
    instruction1: ' Khi bạn cảm thấy thoải mái với trải nghiệm quay video, hãy nhấp vào',
    instruction2: 'ở dưới cùng để tiến hành.',
    warning: 'LƯU Ý: Bạn chỉ có 1 lần thử cho cuộc phỏng vấn này. Xin vui lòng sử dụng câu hỏi này để kiểm thử giọng nói và video của bạn trước khi bạn bắt đầu cuộc phỏng vấn thật sự.',
    coachingWarning: 'LƯU Ý: Bạn chỉ có 1 lần thử cho bài đánh giá thật sự này. Xin vui lòng sử dụng câu hỏi luyện tập này để kiểm thử giọng nói và video của bạn trước khi bạn bắt đầu bài đánh giá thật sự.',
    '10seconds': ' Bạn có 10 giây để trả lời câu hỏi này.',
    allowDevices: 'Bạn cần cho phép trình duyệt truy cập camera và micrô.',
    numOfQuestions: 'Có tổng cộng {{numberOfQuestions}} câu hỏi trong cuộc phỏng vấn này.',
    troubleShootRedirection: 'Đường link hỗ trợ để trợ giúp và khắc phục sự cố camera/micô ',
    clickLink: 'bấm vào đây',
  },
  recording: {
    howManySeconds: 'Bạn có {{giây}} giây để trả lời câu hỏi này.',
    recordingStatus: 'Đang quay video',
    preparationStatus: 'Chuẩn bị',
  },
  submitFailure: {
    ohNo: 'Rất tiếc!',
    description: 'Chúng tôi đã gặp sự cố khi gửi câu trả lời của bạn. Điều này thường là do kết nối mạng không liên tục khiến việc gửi đi không thành công. Mong bạn thứ lỗi vì sự bất tiện này.',
    instruction1: 'Rất tiếc, chúng tôi không thể ghi lại thành công các câu trả lời đã ghi của bạn.',
    instruction2: 'Vui lòng dành chút thời gian để ghi lại câu trả lời của bạn cho những câu hỏi này.',
    instruction3: 'Nếu bạn tiếp tục gặp khó khăn sau khi gửi lại, vui lòng liên hệ với chúng tôi theo địa chỉ ',
    instruction4: 'Bạn cũng có thể ghi lại câu trả lời của mình cho những câu hỏi này trên thiết bị khác bằng cách quét mã qrcode bên dưới.',
    recordAgain: 'Ghi lại',
    networkStatus: 'Trạng thái mạng',
    connected: ' Đã kết nối',
    notConnected: ' Chưa kết nối',
    check1: 'Kiểm tra xem bạn ',
    check2: 'có quyền truy cập internet không ở đây.',
    reSubmit: 'Gửi lại',
  },
  submittedScreen: {
    title: 'Đã gửi câu trả lời của bạn!',
    thankyou: 'Cảm ơn bạn đã dành thời gian quý báu để thực hiện cuộc phỏng vấn với chúng tôi, chúng tôi hy vọng bạn thích trải nghiệm này!',
    redirect1: 'Giờ đây, bạn sẽ được chuyển hướng sau {{time}} giây...',
    orClick: 'Hoặc nhấp vào ',
    here: 'đây',
    ifNotRedirected: ' Nếu bạn không được chuyển hướng',
    redirectToPortal: 'Đăng nhập / Đăng ký vào cổng thông tin ứng viên.',
    clickHere: 'Nhấp vào đây.',
  },
  submittingScreen: {
    title: 'Chúng tôi đang gửi câu trả lời của bạn, xin vui lòng không đóng trình duyệt của bạn...',
  },
  landing: {
    dear: '{{name}} thân mến,',
    hi: 'Xin chào {{name}},',
    previous: 'Trước đó',
    next: 'Kế tiếp',
    description: 'Là một phần của cuộc phỏng vấn trực tuyến, bạn cần phải hoàn thành một cuộc phỏng vấn video cho vai trò',
    coachingDescription: 'Là một phần của bài đánh giá trực tuyến, bạn cần phải hoàn thành một cuộc phỏng vấn video cho vai trò',
    with: 'với ',
    at: 'tại',
    quietSpot: 'Tìm một điểm yên tĩnh có đầy đủ ánh sáng và wifi mạnh để thực hiện bài đánh giá của bạn.',
    instructions: 'Hướng dẫn',
    instruction1: 'Bài đánh giá video này bao gồm',
    instruction1a: 'câu hỏi và bạn sẽ cần',
    instruction1b: 'để hoàn thành, tùy thuộc vào số lượng câu hỏi bạn được chỉ định.',
    instruction2: 'Bạn cần phải hoàn thành bài đánh giá của mình trong một lần thực hiện.',
    instruction3: 'Khi bạn thấy được câu hỏi đầu tiên, đồng hồ đếm giờ tự động sẽ bắt đầu. Bạn sẽ có thời gian chuẩn bị là ',
    instruction3a: ', tiếp theo là giới hạn thời gian quay video có thể thay đổi cho mỗi câu hỏi.',
    instruction4: 'Hãy nhớ để ý đồng hồ đếm giờ trong khi chuẩn bị hoặc quay video câu trả lời của bạn. Nếu bạn thấy mình đã sẵn sàng trước khi hết thời gian giới hạn, bạn có thể chọn bắt đầu quay video câu trả lời của mình hoặc gửi chúng trước khi hết thời gian.',
    instruction5: 'Xin lưu ý: Không làm mới, đóng hoặc thoát trang sau khi cuộc phỏng vấn bắt đầu vì bạn sẽ không được phép truy cập liên kết phỏng vấn đó nữa.',
    coachingInstruction5: 'Xin lưu ý: Không làm mới, đóng hoặc thoát trang sau khi bài đánh giá đã bắt đầu vì bạn sẽ không được phép truy cập liên kết đánh giá đó nữa.',
    instruction6: 'Đừng lo lắng, bạn sẽ có thể xem lại video các câu trả lời của mình khi kết thúc toàn bộ bài đánh giá. Nếu bạn cảm thấy không vừa ý với các câu trả lời của mình, bạn có thể thực hiện lại toàn bộ bài đánh giá',
    instructionRedo: '* Tuy nhiên, thứ tự và bản chất của các câu hỏi có thể thay đổi để đảm bảo bạn gặp được các câu hỏi một cách tự nhiên và tùy ý trong cuộc phỏng vấn video.',
    envCheck: 'Kiểm tra Môi trường/Thiết bị',
    env1: 'Tìm một nơi yên tĩnh có đầy đủ ánh sáng và wifi mạnh để thực hiện bài đánh giá của bạn.',
    env2: 'Hiện tại, tính năng quay video cuộc phỏng vấn chỉ hoạt động trong Chrome và Firefox. Đảm bảo rằng bạn đang sử dụng một trong hai trình duyệt này để thực hiện bài đánh giá của bạn.',
    env3: 'Để tiến hành phỏng vấn video, chúng tôi sẽ cần truy cập camera và micrô của bạn.',
    coachingEnv3: 'Để tiến hành bài đánh giá video, chúng tôi sẽ cần truy cập camera và micrô của bạn.',
    env4: 'Bạn sẽ thấy một câu hỏi luyện tập để có thể thể kiểm thử chất lượng âm thanh và video của mình trước khi bắt đầu bài đánh giá thật sự.',
    env5: 'Đảm bảo bạn xuất hiện trong khung màn hình, ngồi thẳng và nhìn thẳng vào camera khi bạn trình bày câu trả lời.',
    env6: 'Bạn sẽ có thể xem lại các câu trả lời video của mình khi kết thúc toàn bộ bài đánh giá. Nếu bạn cảm thấy không vừa ý với các câu trả lời của mình, bạn có thể thực hiện lại toàn bộ bài đánh giá.',
    readyClick: 'Nhấp vào nút "Tiến hành" bất cứ khi nào bạn đã sẵn sàng. Chúc bạn mọi điều may mắn!',
    resumeOnly: 'Sơ yếu lý lịch (chỉ pdf, doc và docx) *',
    error: 'Xin vui lòng chỉ tải lên tệp PDF. Các tệp bị từ chối',
    env7: 'Không chuyển đổi hoặc chuyển đổi giữa các trình duyệt của bạn trong khi ghi.',
    env8: 'Duy trì giao tiếp bằng mắt và không làm bản thân mất tập trung quá nhiều trong khi ghi lại câu trả lời của mình.',
    env9: 'Âm thanh sẽ được chọn trong khi bạn ghi. Hãy chắc chắn rằng bạn là người duy nhất phát biểu trong quá trình ghi âm cuộc phỏng vấn.',
    instruction7: 'Vui lòng tải lên các giấy tờ tùy thân phù hợp theo quy định (ID Chính phủ, v.v.)',
  },
  resumeParser: {
    title: 'Demo Phân tích Sơ yếu lý lịch',
    description: 'Tải sơ yếu lý lịch lên ở bên dưới để xem cách hệ thống của chúng tôi phân tích và đánh giá sơ yếu lý lịch của ứng viên',
    dragResume: 'Kéo và thả sơ yếu lý lịch tại đây hoặc bấm để chọn tệp (chỉ PDF)',
    parsedResults: 'Kết quả phân tích',
    experience: 'Kinh nghiệm',
    personalDetails: 'Thông tin Cá nhân',
    totalYoE: 'Tổng số Năm Kinh nghiệm',
    company: 'Công ty',
    position: 'Chức vụ',
    duration: 'Khoảng thời gian',
    skills: 'Kỹ năng',
    education: 'Học vấn',
    institute: 'Học viện',
    degree: 'Bằng cấp',
    major: 'Chuyên ngành',
    score: 'Điểm',
    overall: 'Tổng thể',
    betterFitNote: 'Lưu ý: Các ứng viên có điểm sơ yếu lý lịch Tổng thể từ 55-100 sẽ phù hợp hơn dựa trên Yêu cầu và Mô tả Công việc được giao.',
    name: 'Tên',
    email: 'Địa chỉ email',
    phone: 'Số điện thoại',
  },
  countdown: {
    recordIn: 'Ghi âm vào.',
  },
  prepTimer: {
    timer: 'Thời gian chuẩn bị.',
  },
  candidateDetails: {
    back: 'Quay trở lại',
    download: 'Tải xuống',
    questions: 'Câu hỏi',
    videoTranscript: 'Bảng chép lời Video',
    resumeScore: 'Điểm Sơ yếu lý lịch',
    workmapScore: 'Điểm Workmap',
    aiVideoScore: 'Điểm Video AI',
    videoAssessment: 'Bài đánh giá Phỏng vấn Video',
    text: 'Văn bản',
    voice: 'Giọng nói',
    facialExpression: 'Biểu hiện trên Khuôn mặt',
    professionalism: 'Tính chuyên nghiệp',
    energyLevel: 'Mức Năng lượng',
    communication: 'Giao tiếp',
    sociability: 'Tính thân thiện',
    pace: 'Nhịp độ',
    charity: 'Sự rõ ràng',
    sentiments: 'Tình cảm',
    energy: 'Năng lượng',
    positivity: 'Thái độ tích cực',
    positiveEmotion: 'Cảm xúc Tích cực',
    aboutThisAssessmentReport: 'Giới thiệu Về Báo cáo Đánh giá Này',
    introduction: 'Giới thiệu',
    useOfReport: 'Việc sử dụng Báo cáo',
    confidentialityDisclaimer: 'Bảo mật & Tuyên bố Từ chối trách nhiệm',
    workmapAssessment: 'Bài đánh giá Workmap',
    actualScore: 'Điểm Thực tế',
    resumeScoring: 'Chấm điểm Sơ yếu lý lịch',
    excellent: 'Xuất sắc',
    high: 'Cao',
    medium: 'Trung bình',
    low: 'Thấp',
    glossaryIndex: 'Thuật ngữ/Chỉ mục',
    definitionOfWorkMapSkill: 'Định nghĩa Kỹ năng Workmap',
    definitionOfVideoInterviewParameter: 'Định nghĩa các tham số Cuộc phỏng vấn Video',
    candidateSummary: 'Tóm tắt Ứng viên',
    notAvailable: 'Không Có sẵn',

  },
  errors: {
    '404_CANDIDATE_DATA_NOT_FOUND': 'Xin vui lòng hoàn thành cuộc phỏng vấn video của bạn để xem các đánh giá.',
    '404_ASSESSMENT_DATA_NOT_READY': 'Cuộc phỏng vấn video của bạn hiện đang được đánh giá. Hãy quay trở lại sau!',
    '404_CANDIDATE_NOT_AVAILABLE': 'Bạn chưa thực hiện bất kỳ cuộc phỏng vấn video nào. Xin vui lòng quay trở lại sau khi bạn đã gửi câu trả lời phỏng vấn video của mình.',
    default: 'Đã xảy ra lỗi. Xin vui lòng thử lại sau.',
  },
  companyDetails: {
    company: 'Công ty',
    about: 'Giới thiệu',
    experience: 'Kinh nghiệm',
    apply: 'Nộp đơn',
    description: 'Mô tả',
    viewJobDetailText: 'Xem Chi tiết Công việc',
    readMore: 'Xem thêm',
    readLess: 'Thu gọn',
  },
  dashboardPanel: {
    dashboard: 'Trang tổng quan',
    hey: 'Xin chào',
    morningGreeting: 'Chào Buổi sáng',
    afternoonGreeting: 'Chào Buổi chiều',
    eveningGreeting: 'Chào Buổi tối',
    upgrade: 'Nâng cấp',
    support: 'Hỗ trợ',
    signOut: 'Đăng xuất',
    collapse: 'Thu gọn',
    faqs: 'Hỏi Đáp',
    company: 'Công ty',
    role: 'Vai trò',
    status: 'Trạng thái',
    action: 'Hành động',
    date: 'Ngày',
    recordNow: 'Quay video Ngay',
    responded: 'Đã trả lời',
    processing: 'Đang xử lý',
    viewDetails: 'Xem chi tiết',
    personalInfo: 'Thông tin Cá nhân',
    introVideo: 'Video Giới thiệu',
    score: 'Điểm',
    email: 'Địa chỉ email',
    phone: 'Số điện thoại',
    summary: 'Tóm tắt',
    topSkill: 'Kỹ năng Hàng đầu',
    workmapScore: 'Điểm Workmap',
    aiVideoScore: 'Điểm Video AI',
    introText1: 'Hãy sẵn sàng cho những thông tin phân tích thú vị. Bắt đầu với việc quay',
    introText2: 'video giới thiệu của bạn.',
    recordVideo: 'Quay Video',
    settings: 'Cài đặt',
  },
  candidatePortalSettings: {
    settings: 'Settings',
    publicProfile: 'Public Profile',
    profileVisibility: 'Your profile is visible to everyone.',
    closeAccount: ' Close Account',
    primaryConfirmationText: 'we\'re sorry to see you go. Are you sure you want to close your account?',
    secondaryConfirmationText: 'This would erase all your data and any mock interview that you may have responded to. Please not this will not withdraw your interest from any application you have made.',
    reasoningHeading: ' Tell us why you\'re closing your account:',
    primaryFeedbackText: 'We value your feedback, but please note that we aren\'t able to respond to comments about your account submitedin this from. if you have a question or request about your account that requires attention please contact us.',
    clickHere: 'Click Here',
    buttonSectionPrimarytext: 'Please confirm your account close?',
    deleteButtonText: 'Yes, I\'m',
    cancelButtonText: 'Not Now',
    modalConfirmtext: 'This action will lead to deletion/withdrawal of any application you made in the past using our platform. Confirm to proceed.',
    confirm: ' Confirm',
  },
  purchaseBanner: {
    heading: 'Hãy nâng cấp để xem báo cáo phỏng vấn AI chi tiết của bạn',
    subHeading: 'Hãy tự tin trong vòng phỏng vấn tiếp theo của bạn.',
    launchAt: 'Ưu đãi ra mắt với giá',
    only: 'Chỉ',
    year: 'năm',
  },
  additionalFormField: {
    chooseFileBtn: 'Chọn tệp',
    pleaseChooseAFileText: 'Xin vui lòng chọn một tệp',
  },
  domainQuestion: {
    domainAssessment: 'Đánh giá lĩnh vực',
    of: 'của',
    submit: 'Đang gửi đi',
    questions: 'câu hỏi',
    answers: 'Câu trả lời',
    submittedAssessment: 'Bạn đã gửi bài đánh giá tên miền.',
    autoRedirect: ' Tự động chuyển hướng đến bước tiếp theo sau 3 giây...',
  },
  quitInterviewModal: {
    confirmQuit: 'Xác nhận Bỏ cuộc phỏng vấn',
    areYouSure: 'Bạn có chắc chắn muốn thoát khỏi cuộc phỏng vấn không?',
    confirm: 'Xác nhận',
    cancel: 'Hủy bỏ',
  },
  processingVideo: {
    instruction1: 'Xử lý video của bạn...',
    instruction2: 'Vui lòng chờ',
    instruction3: 'Sẽ chỉ mất vài giây...',
    instruction4: 'Kiên nhẫn.',
    noAudioFound: 'Không tìm thấy âm thanh, vui lòng ghi lại với âm thanh rõ ràng. Vui lòng kiểm tra kết nối micrô của bạn.',
  },
  audioError: {
    message: 'Chúng tôi rất tiếc đã xảy ra lỗi khi sao chép video của bạn. Vui lòng kiểm tra âm thanh của bạn và thử lại.',
  },
  reject: {
    thanksForInterest: 'Cảm ơn bạn đã quan tâm.',
    notQualified: 'Rất tiếc, bạn không đủ điều kiện để tiếp tục với đơn này.',
    bestWishes: 'Chúng tôi chúc bạn mọi điều tốt đẹp nhất.',
  },
};
