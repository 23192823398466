export default {
  helloApplicant: 'नमस्ते आवेदक,',
  thankYou: 'धन्यवाद।',
  question: 'सवाल',
  start: 'शुरू',
  submitting: 'भेजने से',
  submit: 'जमा करना',
  sending: 'भेजा जा रहा है',
  proceed: 'आगे बढ़ना',
  close: 'बंद करना',
  poweredBy: 'द्वारा संचालित',
  contactUs: 'हमसे संपर्क करें',
  showMore: 'और दिखाएँ',
  showLess: 'कम दिखाएँ',
  applying: 'को लागू करने',
  apply: 'आवेदन करना',
  click: 'क्लिक',
  done: 'हो गया',
  uploading: 'अपलोड हो रहा है',
  cantFindPage: 'हमें वह पेज नहीं मिल रहा जिसे आप ढूंढ रहे हैं।',
  usefulLinks: 'यहां कुछ लिंक दिए गए हैं जो उपयोगी हो सकते हैं',
  menuHome: 'घर',
  menuAboutUs: 'हमारे बारे में',
  menuTnC: 'नियम और शर्तें',
  menuPrivacy: 'गोपनीयता',
  tnc1: 'इस बॉक्स को चेक करके, आप इससे सहमत हैं ',
  tnc3: 'इस बॉक्स को चेक करके, आप इससे सहमत हैं ',
  tnc2: 'साक्षात्कारकर्ता.एआई उम्मीदवार उपयोग की शर्तें',
  amInterested: 'मेरी रूचि है',
  second: 'दूसरा',
  second_plural: 'सेकंड',
  secondWithCount: '{{count}} दूसरा',
  secondWithCount_plural: '{{count}} सेकंड',
  minute: 'मिनट',
  minute_plural: 'मिनट',
  minuteWithCount: '{{count}} मिनट',
  minuteWithCount_plural: '{{count}} मिनट',
  workmapScreen: {
    greeting: 'प्रिय {{name}},',
    unansweredQuestions: 'आपके अनुत्तरित प्रश्न हैं:',
    description:
      'की भूमिका के लिए आपको वर्कमैप (कौशल) मूल्यांकन में भाग लेने के लिए आमंत्रित किया जाता है {{jobName}} साथ {{companyName}}.',
    instruction: 'अनुदेश',
    instruction1:
      'कृपया प्रत्येक कथन का उत्तर इस आधार पर दें कि आप कार्यस्थल पर अपने अधिकांश समय में कैसे रहे हैं।',
    instruction2:
      'बयानों का जवाब देते समय, उस विकल्प का चयन करें जो दर्शाता है कि आप आम तौर पर कैसे सोचते हैं या महसूस करते हैं।',
    instruction3:
      'इस मूल्यांकन में कोई सही या गलत उत्तर नहीं है, इसलिए किसी एक उत्तर पर बहुत अधिक समय न खर्च करें।',
    instruction4:
      'सहज रहें और जो आप सोचते हैं कि हम सुनना चाहते हैं उसके आधार पर उत्तर देने का प्रयास न करें।',
    instruction5: 'अपने उत्तरों में ईमानदार रहें. ',
    instruction6:
      'इस मूल्यांकन को पूरा होने में लगभग 5 मिनट का समय लगना चाहिए। ',
    goBack: 'वापस जाओ',
    skillsAssessment: 'कौशल का आकलन',
    of: 'का',
    answers: 'जवाब',
    questions: 'प्रश्न',
    submit: 'जमा करना',
    start: 'शुरू',
    instruction7: 'आपके पास अभी भी अनुत्तरित प्रश्न हैं. ',
    stronglyDisagree: 'दृढ़तापूर्वक असहमत',
    disagree: 'असहमत',
    somewhatDisagree: 'कुछ हद तक असहमत',
    neitherAgreeNorDisagree: 'ना तो सहमत ना ही असहमत',
    somewhatAgree: 'कुछ हद तक सहमत',
    agree: 'सहमत',
    stronglyAgree: 'दृढ़तापूर्वक सहमत',
    aiaInstruction1: '',
    aiaInstruction2: '',
    aiaInstruction3: '',
  },
  scale: {
    'Not at all': 'बिल्कुल नहीं',
    Rarely: 'कभी-कभार',
    Sometimes: 'कभी-कभी',
    Often: 'अक्सर',
    'Very Often': 'बहुत बार',
    'Strongly Disagree': 'दृढ़तापूर्वक असहमत',
    'Somewhat Disagree': 'कुछ हद तक असहमत',
    Disagree: 'असहमत',
    'Neither Agree nor Disagree': 'ना तो सहमत ना ही असहमत',
    Neutral: 'तटस्थ',
    Agree: 'सहमत',
    'Somewhat Agree': 'कुछ हद तक सहमत',
    'Strongly Agree': 'दृढ़तापूर्वक सहमत',
  },
  applySendSuccessful: {
    thankyou: 'आपके निवेदन के लिए धन्यवाद!',
    instruction: 'हमने आपको साक्षात्कार पृष्ठ के लिंक के साथ एक ईमेल भेजा है। ',
    instruction1: '',
    instruction2: '',
    instruction3: '',
  },
  applySuccessful: {
    congrats: 'अरे, {{name}}!',
    submitted: 'दी गई जानकारी के लिए धन्यवाद.',
    whatsNext: 'नया क्या है ?',
    instruction:
      'आपकी आवेदन प्रक्रिया में अगला चरण साक्षात्कार प्रश्नों के वीडियो उत्तर रिकॉर्ड करना है।',
    coachingInstruction:
      'ऑनलाइन मूल्यांकन के भाग के रूप में, आपको मूल्यांकन प्रश्न(प्रश्नों) पर एक वीडियो प्रतिक्रिया रिकॉर्ड करना आवश्यक है',
    instruction1:
      'एक शांत स्थान चुनें और सुनिश्चित करें कि आपका ऑडियो और प्रकाश व्यवस्था स्पष्ट हो',
    instruction2:
      'शुरू करने से पहले जांच लें कि कनेक्टिविटी में कोई समस्या तो नहीं है',
    instruction3:
      'आश्वस्त रहें और ज़ोर से बोलें ताकि ऑडियो अच्छे से कैप्चर हो जाए',
    instruction4: 'वीडियो साक्षात्कार उचित समय के भीतर पूरा किया जाना चाहिए। ',
    instruction5: 'हम आपके वीडियो प्रतिक्रियाओं की प्रतीक्षा कर रहे हैं। ',
    giveBestShot:
      'यह आपके व्यक्तित्व, जुनून और ऊर्जा को प्रदर्शित करने का अवसर है इसलिए इसे अपना सर्वश्रेष्ठ प्रयास दें!',
    helpfulTips: 'उपयोगी सुझाव:',
    doWithin48: 'इसे 48 घंटे के भीतर करें - मुझे लिंक भेजें',
    wsgClientInstruction1:
      '5. कृपया ध्यान दें कि WSG नियोक्ताओं को सबमिट करने से पहले सभी रिकॉर्ड किए गए वीडियो की स्क्रीनिंग करेगा। ',
    doNow: 'शुरू करें',
    infoProvide: 'दी गई जानकारी के लिए धन्यवाद',
    opportunityText:
      'यह आपके व्यक्तित्व, जुनून और ऊर्जा को प्रदर्शित करने का अवसर है इसलिए इसे अपना सर्वश्रेष्ठ प्रयास दें!',
    quietSpot: 'एक अच्छी जगह ढूंढो',
    strongNetwork: 'मजबूत नेटवर्क',
    confidentLoud: 'स्पष्ट रूप से बोलो',
    videoCompleted: 'प्रविष्टियों',
  },
  applySuccessfulDelayInterview: {
    thankyou: 'धन्यवाद {{name}}!',
    submitted: 'आपका आवेदन जमा कर दिया गया है.',
    instruction:
      'हमारी टीम आपके आवेदन की समीक्षा करेगी और यदि आपको अगले दौर के लिए शॉर्टलिस्ट किया जा रहा है तो शीघ्र ही आपसे संपर्क करेगी। ',
    goodLuck: 'शुभकामनाएँ!',
  },
  candidateProfile: {
    intro: 'अपना परिचय दें',
    instruction:
      ' वीडियो साक्षात्कार लेने से पहले, हम आपसे अनुरोध करते हैं कि आप अपना परिचय देने के लिए कुछ समय निकालें।',
    placeholder: 'अपने बारे में हमें बताएं',
  },
  browser: {
    worksBest:
      'इंटरव्यूअर.एआई इस समय क्रोम और फायरफॉक्स में सबसे अच्छा काम करता है।',
    bestExperience:
      'हम यह सुनिश्चित करने के लिए काम कर रहे हैं कि हर किसी को साक्षात्कार में सर्वोत्तम अनुभव हो!',
    meantime:
      'इस बीच, Google Chrome या मोज़िला फ़ायरफ़ॉक्स का नवीनतम संस्करण इंस्टॉल करें और अपने साक्षात्कार के लिए उसी साक्षात्कार लिंक तक पहुंचें:',
    downloadChrome: 'क्रोम डाउनलोड करें',
    downloadFirefox: 'फ़ायरफ़ॉक्स डाउनलोड करें',
    lookForward: 'हम आपकी प्रतिक्रियाओं की प्रतीक्षा कर रहे हैं!',
    bestRegards: 'साभार,',
    interviewerTeam: 'साक्षात्कारकर्ता.एआई टीम',
    scanQrCode: 'मोबाइल पर रिकॉर्ड करने के लिए QR कोड स्कैन करें',
  },
  digitalProfile: {
    interviews: 'साक्षात्कार',
    interviewName: 'साक्षात्कार का नाम',
    status: 'स्थिति',
    dateResponded: 'जवाब देने की तिथि',
    summary: 'सारांश',
    topSkills: 'शीर्ष कौशल',
    viewResume: 'बायोडाटा देखें',
    visitLinkedin: 'लिंक्डइन पर जाएँ',
    myWorkmapScore: 'मेरा वर्कमैप स्कोर',
  },
  organisationIntroVideoScreen: {
    continue: 'जारी रखना',
    note: '(नोट: पूरा वीडियो देखने के बाद जारी रखें बटन सक्षम हो जाएगा)',
  },
  endedScreen: {
    recordAgain: 'दोबारा रिकॉर्ड करें',
    recordAgainQuestion: 'क्या आप वाकई फिर से रिकॉर्ड करना चाहते हैं?',
    recordAgainDescription:
      'ध्यान रखें कि इसके लिए आपको प्रश्न 1 से पूरा साक्षात्कार दोबारा करना होगा। पुनः आरंभ करने के लिए फिर से रिकॉर्ड करें बटन दबाएँ, या आप इस विंडो को बंद कर सकते हैं और अपनी प्रतिक्रियाएँ सबमिट करने के लिए आगे बढ़ सकते हैं।',
    thankyou: 'धन्यवाद {{name}}! ',
    coachingThankyou: 'धन्यवाद {{name}}! ',
    instruction1: 'आप ऊपर दी गई अपनी प्रतिक्रियाओं की पहले समीक्षा कर सकते हैं',
    instruction2: ' उन्हें पृष्ठ के नीचे दिए गए बटन से सबमिट करें।',
    getFeedback:
      'हमारे उत्पाद को बेहतर बनाने में हमारी मदद करने के लिए, हम नीचे दी गई आपकी प्रतिक्रिया की सराहना करते हैं! ',
    feedback: 'प्रतिक्रिया',
    placeholder: 'आपकी प्रतिपुष्टियों के लिए के लिए हम बहुत आभारी हैं!',
    rateExp: 'अपने साक्षात्कार अनुभव का मूल्यांकन करें',
    Hi: 'अरे!',
    checkVideo: 'चलिए आपका वीडियो देखते हैं.',
    question: 'प्रश्न',
    submit: 'जमा करना',
  },
  thankyouEndScreen: {
    radioText1: 'नौकरी में रुचि नहीं है',
    radioText2: 'वीडियो इंटरव्यू में कोई दिलचस्पी नहीं',
    radioText3: 'बादमे करुँगी',
    radioText4: 'प्रस्तुत करने में असमर्थ',
    radioText5: 'अन्य',
    pleaseChooseReason: 'कृपया कोई कारण चुनें',
    stateYourReason: 'कृपया अपना कारण बताएं',
    notFinished: 'आपने साक्षात्कार समाप्त नहीं किया है.',
    notSubmittedVideoResp: 'आपकी वीडियो प्रतिक्रिया सबमिट नहीं की गई है.',
    noSubmittion: 'कोई सबमिशन नहीं!',
    shareYourFeedback: 'कृपया अपनी प्रतिक्रिया साझा करें.',
    tellUsQuittingInterview:
      'यदि आप साक्षात्कार छोड़ रहे हैं या किसी चुनौती का सामना कर रहे हैं तो हमें बताएं।',
    ratingStarsMandatory: 'रेटिंग स्टार अनिवार्य हैं',
    pleaseDontLeaveField: 'कृपया इस फ़ील्ड को खाली न छोड़ें',
    congratulations: 'बधाई हो',
    responseSent: 'आपकी वीडियो प्रतिक्रियाएँ सफलतापूर्वक भेज दी गई हैं।',
    instruction1:
      'सफल सबमिशन संदेश मिलने तक प्रतीक्षा करें क्योंकि आपका आवेदन प्रक्रियाधीन हो सकता है',
    instruction2:
      'हम मिनटों में आपकी प्रतिक्रिया का मूल्यांकन करने की पूरी कोशिश करते हैं। ',
    instruction3: 'इसका मूल्यांकन हो जाने पर आपको एक ईमेल प्राप्त होगा। ',
    instruction4:
      'किसी भी अतिरिक्त प्रश्न के लिए आप support@interviewer.ai पर हमसे संपर्क कर सकते हैं। ',
    finishedInterview: 'आपने साक्षात्कार समाप्त कर लिया है.',
    shareExperience: 'अपना रिकॉर्डिंग अनुभव साझा करें.',
    submit: 'जमा करना',
    thankyou: 'धन्यवाद',
    feedbackText:
      'हमारे उत्पाद को बेहतर बनाने में हमारी मदद करने के लिए, हम नीचे दी गई आपकी प्रतिक्रिया की सराहना करते हैं! ',
    feedback: 'प्रतिक्रिया',
  },
  interviewCompleted: {
    submitted: 'आपने साक्षात्कार का उत्तर सबमिट कर दिया है.',
    thankyou: 'आपकी प्रतिक्रिया के लिए धन्यवाद! ',
  },
  interviewFailure: {
    errorMessage:
      'हमने देखा है कि आपके द्वारा एक्सेस किया गया लिंक अमान्य है या मौजूद नहीं है। ',
    title: 'उफ़, साक्षात्कार लिंक अमान्य प्रतीत होता है!',
    contactUsBody:
      'वीडियो साक्षात्कार लिंक तक नहीं पहुंच सका&body=हाय, मैं साक्षात्कार लिंक तक नहीं पहुंच सका: {{- link }}. ',
  },
  interviewSignUp: {
    name: 'नाम',
    apply: 'अभी अप्लाई करें',
    submit: 'जमा करना',
    experience: 'अनुभव',
    seniorityLevel: 'वरिष्ठता स्तर',
    employmentType: 'रोजगार के प्रकार',
    roleOverview: 'सिंहावलोकन',
    companyName: 'कंपनी',
    namePlaceholder: 'आपका नाम',
    applicationForm: 'आवेदन फार्म',
    required: 'आवश्यक',
    email: 'ईमेल',
    emailPlaceholder: 'your_email@example.com',
    school: 'विद्यालय',
    phone: 'फ़ोन',
    linkedin: 'लिंक्डइन प्रोफ़ाइल',
    facebook: 'फेसबुक की रूपरेखा',
    identityProof: 'प्रमाण पहचानें',
    identityTypes: '(पासपोर्ट/ड्राइवर लाइसेंस/सरकार द्वारा जारी आईडी)',
    identityFormats: 'स्वीकृत प्रारूप: जेपीजी अधिकतम फ़ाइल आकार: 2 एमबी',
    instagram: 'इंस्टाग्राम प्रोफाइल',
    resume: 'बायोडाटा (केवल पीडीएफ, डॉक और डॉक्स)',
    errorExpired: 'उफ़, साक्षात्कार लिंक समाप्त हो गया लगता है!',
    errorMessage:
      'हमने देखा है कि आपके द्वारा एक्सेस किया गया लिंक अब उपलब्ध नहीं है। ',
    contactUsBody:
      'वीडियो साक्षात्कार लिंक तक नहीं पहुंच सका&body=हाय, मैं साक्षात्कार लिंक तक नहीं पहुंच सका: {{- link }}. ',
    toApplyFor: 'लागू करने के लिए',
    coachingToApplyFor:
      'अपना मूल्यांकन जारी रखने के लिए कृपया नीचे विवरण सबमिट करें:',
    submitInfo: ' , कृपया अपनी जानकारी नीचे सबमिट करें:',
    selectFile: 'फ़ाइल चुनें',
    supportPDF: 'केवल पीडीएफ, डॉक और डॉक्स का समर्थन करें',
    dragPDF: 'अपनी फ़ाइल को यहां खींचें या क्लिक करें',
    browse: 'ब्राउज़',
    your: 'आपका',
    prefilledNameNote:
      'हमें आपकी प्रोफ़ाइल इस ईमेल से संबद्ध मिली, इसलिए हमने इसे आपके लिए पहले से ही भर दिया है। ',
    IP: {
      programOfStudy: 'अध्ययन कार्यक्रम',
      school: 'विद्यालय',
      formTitle: 'इनसाइटप्रो ऑनलाइन मॉक वीडियो साक्षात्कार पंजीकरण',
      instruction1:
        '1. पंजीकरण पूरा करने पर, आप या तो तुरंत वीडियो साक्षात्कार शुरू करना चुन सकते हैं या ईमेल के माध्यम से दिए गए लिंक के माध्यम से इसे बाद में पूरा कर सकते हैं।',
      instruction2:
        '2. साक्षात्कार में 8 व्यवहार संबंधी प्रश्न शामिल हैं और यह लगभग 15 मिनट तक चलेगा।',
      instruction3:
        '3. आपकी साक्षात्कार मूल्यांकन रिपोर्ट साक्षात्कार पूरा होने के 3 व्यावसायिक दिनों के भीतर पंजीकृत ईमेल पर भेज दी जाएगी।',
      instruction4:
        '* कृपया ध्यान दें कि अपूर्ण या अमान्य साक्षात्कारों की समीक्षा नहीं की जाएगी और इस प्रकार कोई मूल्यांकन परिणाम उपलब्ध नहीं होगा।',
      toApplyFor: 'लागू करने के लिए',
      at: 'पर',
      howYouHear: 'आपने इस घटना के बारे में कैसे सुना है?',
      indicateIndustry:
        '(कृपया बताएं कि आप किस उद्योग और पद में रुचि रखते हैं।)',
      yearOfGrad: 'स्नातक का वर्ष',
      careerInterests: 'कैरियर रुचियाँ',
      submitInfo: ' , कृपया अपनी जानकारी नीचे सबमिट करें:',
      linkedin: 'सोशल मीडिया - लिंक्डइन',
      wechat: 'सोशल मीडिया - वीचैट',
      friend: 'दोस्त',
      infoBooth: 'सूचना बूथ',
      searchEngine: 'खोज इंजन',
    },
    sopDocument: 'व्यक्तिगत विवरण (एसओपी) दस्तावेज़',
  },
  interviewSignUpSideScreen: {
    processHeadingWithWorkmapEnabled: '3 चरणीय प्रक्रिया',
    processHeadingWithoutWorkmapEnabled: '2 चरणीय प्रक्रिया',
    fillApplication: 'आवेदन भरें',
    completeAssessment: 'संपूर्ण मूल्यांकन',
    videoResponse: 'वीडियो प्रतिक्रिया रिकॉर्ड करें',
  },
  multiAttempts: {
    title: 'अरे नहीं, लिंक केवल एक बार के उपयोग के लिए मान्य है!',
    errorMessage:
      'हमने देखा है कि आपके द्वारा एक्सेस किया गया लिंक अब उपलब्ध नहीं है। ',
    contactUsBody:
      'साक्षात्कार लिंक केवल एक बार के उपयोग के लिए मान्य है&body=हाय, मैं साक्षात्कार लिंक तक नहीं पहुंच सका: {{- link }}. ',
  },
  preQuestionReview: {
    title: 'यह परीक्षण प्रश्न की रिकॉर्डिंग है',
    pleaseNote: 'कृपया ध्यान दें:',
    description1:
      'हालाँकि यह वीडियो सबमिट नहीं किया जाएगा, आपका वीडियो इस प्रकार दिखाई देगा ',
    description2: '. ',
    employer: 'नियोक्ता',
    warning:
      'ध्यान दें: एक बार जब आप साक्षात्कार शुरू कर देंगे, तो आप वापस नहीं जा पाएंगे या पुनः आरंभ नहीं कर पाएंगे।',
    coachingWarning:
      'ध्यान दें: एक बार मूल्यांकन शुरू करने के बाद, आप वापस नहीं जा पाएंगे या पुनः आरंभ नहीं कर पाएंगे।',
    instruction1: 'जब आप इंटरव्यू शुरू करते हैं तो आपके पास सिर्फ यही होता है',
    coachingInstruction1:
      'जब आप मूल्यांकन शुरू करते हैं, तो आपके पास केवल यही होता है',
    instruction2: 'प्रश्न पढ़ें और अपने उत्तर की तैयारी करें। ',
    prepTime: '{{prepTime}} सेकंड ',
    troubleShootRedirection:
      'कैम/माइक समस्याओं की सहायता और समस्या निवारण के लिए समर्थन लिंक ',
    clickLink: 'यहाँ क्लिक करें',
  },
  interview: {
    testAgain: 'फिर से परीक्षण करें',
    startInterview: 'साक्षात्कार प्रारंभ करें',
    recordResponse: 'प्रतिक्रिया रिकार्ड करें',
    goodLuck: 'आपको कामयाबी मिले!',
    interview: 'कीमत',
    '10seconds': ' इस प्रश्न का उत्तर देने के लिए आपके पास 10 सेकंड हैं।',
    allowDevices:
      'आपको ब्राउज़र को कैमरा और माइक्रोफ़ोन तक पहुंचने की अनुमति देनी होगी।',
    done: 'हो गया',
    preparation: 'तैयारी',
    testQuestion: 'यह एक परीक्षण प्रश्न है',
    remainingTime: 'शेष समय',
    back: 'पीछे',
    checkYourVideo: 'अपना वीडियो जांचें',
    okayLetsProceed: 'ठीक है, चलिए आगे बढ़ते हैं',
    startPractice: 'अभ्यास प्रारंभ करें',
    importantNotes: 'महत्वपूर्ण टिप्पणियाँ:',
    instruction1: 'सबसे पहले, एक अभ्यास प्रश्न के साथ परीक्षण करें',
    instruction2: 'इसे सबमिशन के रूप में नहीं गिना जाएगा',
    instruction3: 'शुरू करने के लिए स्टार्ट टेस्ट पर क्लिक करें',
    startTest: 'परीक्षण प्रारंभ करें',
    alreadyApplied: 'पहले से ही लागू!',
    alreadyAppliedToTheJob: 'आप पहले ही इस नौकरी के लिए आवेदन कर चुके हैं',
    clickBelowToProceed: 'आगे बढ़ने के लिए नीचे क्लिक करें.',
    continue: 'जारी रखना',
    blockerHeading:
      'आगे बढ़ने के लिए आपको अपना माइक्रोफ़ोन और वीडियो अनुमतियाँ चालू करनी होंगी।',
    blockerPrimaryText:
      'माइक्रोफ़ोन और वीडियो अनुमतियाँ चालू करने के लिए कृपया निम्नलिखित चरणों का पालन करें।',
    forChrome: 'क्रोम के लिए:',
    chromeBlockerStep1: '1) ऊपर दाईं ओर, अधिक पर क्लिक करें। ',
    chromeBlockerStep2: '2) गोपनीयता और सुरक्षा साइट सेटिंग्स पर क्लिक करें। ',
    forFireFox: 'फ़ायरफ़ॉक्स के लिए:',
    firefoxBlockerStep1: '1) मेनू बटन पर क्लिक करें और सेटिंग्स चुनें।',
    firefoxBlockerStep2: '2) बाएं मेनू से गोपनीयता और सुरक्षा पर क्लिक करें।',
    firefoxBlockerStep3: '3) अनुमतियाँ अनुभाग तक नीचे स्क्रॉल करें।',
    firefoxBlockerStep4:
      '4) माइक्रोफ़ोन और वीडियो विकल्प के लिए सेटिंग बटन पर क्लिक करें',
    questions: 'सवाल',
  },
  preQuestion: {
    qtitle: 'हमें अपना पसंदीदा रंग बताएं और क्यों?',
    title1: 'यह एक है',
    title2: ' अभ्यास प्रश्न ',
    title3: 'अपने वीडियो और ऑडियो का परीक्षण करने के लिए।',
    notSubmitted: 'इसे आपके सबमिशन में नहीं जोड़ा जाएगा.',
    toTest: 'परीक्षा करना',
    instruction1:
      ' एक बार जब आप रिकॉर्डिंग अनुभव से सहज हो जाएं, तो क्लिक करें',
    instruction2: 'आगे बढ़ने के लिए सबसे नीचे.',
    warning: 'ध्यान दें: इस साक्षात्कार के लिए आपके पास केवल 1 प्रयास है। ',
    coachingWarning:
      'ध्यान दें: इस वास्तविक मूल्यांकन के लिए आपके पास केवल 1 प्रयास है। ',
    '10seconds': ' इस प्रश्न का उत्तर देने के लिए आपके पास 10 सेकंड हैं।',
    allowDevices:
      'आपको ब्राउज़र को कैमरा और माइक्रोफ़ोन तक पहुंचने की अनुमति देनी होगी।',
    numOfQuestions: 'कुल हैं {{numberOfQuestions}} इस साक्षात्कार में प्रश्न.',
    troubleShootRedirection:
      'कैम/माइक समस्याओं की सहायता और समस्या निवारण के लिए समर्थन लिंक ',
    clickLink: 'यहाँ क्लिक करें',
  },
  recording: {
    howManySeconds:
      'आपके पास {{seconds}} इस प्रश्न का उत्तर देने में कुछ सेकंड।',
    recordingStatus: 'रिकॉर्डिंग',
    preparationStatus: 'तैयारी',
  },
  submitFailure: {
    ohNo: 'अरे नहीं!',
    description: '',
    instruction1:
      'दुर्भाग्य से, हम आपके रिकॉर्ड किए गए उत्तरों को सफलतापूर्वक कैप्चर करने में असमर्थ रहे।',
    instruction2:
      'कृपया इन प्रश्नों के लिए अपनी प्रतिक्रियाएँ पुनः रिकॉर्ड करने के लिए कुछ समय निकालें।',
    instruction3:
      'यदि दोबारा सबमिट करने के बाद भी आपको चुनौतियों का सामना करना पड़ रहा है, तो बेझिझक हमसे संपर्क करें ',
    instruction4:
      'आप नीचे दिए गए क्यूआरकोड को स्कैन करके अन्य डिवाइस पर भी इन प्रश्नों के लिए अपनी प्रतिक्रियाएँ पुनः रिकॉर्ड कर सकते हैं।',
    recordAgain: 'दोबारा रिकॉर्ड करें',
    networkStatus: 'नेटवर्क स्थिति',
    connected: ' जुड़े हुए',
    notConnected: ' जुड़े नहीं हैं',
    check1: 'जांचें कि क्या आप ',
    check2: 'यहां इंटरनेट तक पहुंच है.',
    reSubmit: 'पुनः सबमिट करें',
  },
  submittedScreen: {
    title: 'आपकी प्रतिक्रिया सबमिट कर दी गई है!',
    thankyou:
      'हमारे साथ साक्षात्कार के लिए अपना बहुमूल्य समय देने के लिए धन्यवाद, हमें आशा है कि आपने अनुभव का आनंद लिया होगा!',
    redirect1: 'अब आपको पुनः निर्देशित किया जाएगा {{time}} सेकंड...',
    orClick: 'या क्लिक करें ',
    here: 'यहाँ',
    ifNotRedirected: ' यदि आप पुनर्निर्देशित नहीं हैं',
    redirectToPortal: 'उम्मीदवार पोर्टल पर लॉगिन/साइन अप करने के लिए।',
    clickHere: 'यहाँ क्लिक करें',
  },
  submittingScreen: {
    title:
      'हम आपकी प्रतिक्रियाएँ प्रस्तुत कर रहे हैं, कृपया अपना ब्राउज़र बंद न करें...',
  },
  landing: {
    dear: 'प्रिय {{name}},',
    hi: 'नमस्ते {{name}},',
    previous: 'पहले का',
    next: 'अगला',
    description:
      'ऑनलाइन साक्षात्कार के भाग के रूप में, आपको भूमिका के लिए एक वीडियो साक्षात्कार पूरा करना होगा',
    coachingDescription:
      'ऑनलाइन मूल्यांकन के भाग के रूप में, आपको भूमिका के लिए एक वीडियो साक्षात्कार पूरा करना होगा',
    with: 'साथ ',
    at: 'पर',
    quietSpot:
      'अपना मूल्यांकन लेने के लिए पर्याप्त रोशनी और मजबूत वाईफाई वाला एक शांत स्थान ढूंढें।',
    instructions: 'निर्देश',
    instruction1: 'इस वीडियो मूल्यांकन में शामिल हैं',
    instruction1a: 'प्रश्न(ओं) और आपको लेना चाहिए',
    instruction1b: 'आपको सौंपे गए प्रश्नों की संख्या के आधार पर पूरा करना।',
    instruction2: 'आपको अपना मूल्यांकन एक ही बैठक में पूरा करना होगा।',
    instruction3:
      'एक बार जब आप अपना पहला प्रश्न प्रस्तुत कर देते हैं, तो एक स्वचालित टाइमर शुरू हो जाता है।  ',
    instruction3a:
      ', इसके बाद एक रिकॉर्डिंग समय सीमा होती है जो प्रत्येक प्रश्न के लिए भिन्न हो सकती है।',
    instruction4:
      'अपनी प्रतिक्रिया तैयार करते या रिकॉर्ड करते समय टाइमर का ध्यान रखना याद रखें। ',
    instruction5:
      'कृपया ध्यान दें: साक्षात्कार शुरू होने के बाद पृष्ठ को ताज़ा न करें, बंद न करें या बाहर न निकलें, क्योंकि अब आपको साक्षात्कार लिंक तक पहुंचने की अनुमति नहीं दी जाएगी।',
    coachingInstruction5:
      'कृपया ध्यान दें: मूल्यांकन शुरू होने के बाद पृष्ठ को ताज़ा न करें, बंद न करें या बाहर न निकलें, क्योंकि अब आपको मूल्यांकन लिंक तक पहुंचने की अनुमति नहीं दी जाएगी।',
    instruction6:
      'चिंता न करें, आप संपूर्ण मूल्यांकन के अंत में अपने वीडियो प्रतिक्रियाओं की समीक्षा करने में सक्षम होंगे। ',
    instructionRedo:
      '* हालाँकि, यह सुनिश्चित करने के लिए कि वीडियो साक्षात्कार के दौरान आप स्वाभाविक और सहज लगें, प्रश्नों का क्रम और प्रकृति परिवर्तन के अधीन है।',
    envCheck: 'उपकरण/पर्यावरण जांच',
    env1: 'अपना मूल्यांकन लेने के लिए पर्याप्त रोशनी और मजबूत वाईफाई वाला एक शांत स्थान ढूंढें।',
    env2: 'साक्षात्कार रिकॉर्डिंग फिलहाल केवल क्रोम और फ़ायरफ़ॉक्स में काम करती है। ',
    env3: 'वीडियो साक्षात्कार के साथ आगे बढ़ने के लिए, हमें आपके वीडियो कैमरे और आपके माइक्रोफ़ोन तक पहुंच की आवश्यकता होगी।',
    coachingEnv3:
      'वीडियो मूल्यांकन के साथ आगे बढ़ने के लिए, हमें आपके वीडियो कैमरे और आपके माइक्रोफ़ोन तक पहुंच की आवश्यकता होगी।',
    env4: 'आपको एक अभ्यास प्रश्न दिखाया जाएगा जहां आप वास्तविक मूल्यांकन शुरू करने से पहले अपने ऑडियो और वीडियो की गुणवत्ता का परीक्षण कर सकते हैं।',
    env5: 'अपने आप को अपनी स्क्रीन के फ्रेम के भीतर अच्छी तरह से रखना सुनिश्चित करें, सीधे बैठें और अपनी प्रतिक्रियाएँ प्रस्तुत करते समय सीधे अपने कैमरे में देखें।',
    env6: 'आप संपूर्ण मूल्यांकन के अंत में अपने वीडियो प्रतिक्रियाओं की समीक्षा करने में सक्षम होंगे। ',
    readyClick: 'जब भी आप तैयार हों तो "आगे बढ़ें" बटन पर क्लिक करें। ',
    resumeOnly: 'बायोडाटा (केवल पीडीएफ, डॉक और डॉक्स) *',
    error: 'कृपया केवल पीडीएफ फाइल ही अपलोड करें। ',
    env7: 'रिकॉर्डिंग करते समय अपने ब्राउज़रों के बीच टॉगल या स्विच न करें।',
    env8: 'अपनी प्रतिक्रियाएँ रिकॉर्ड करते समय अपनी आंखों का संपर्क बनाए रखें और अपना ध्यान बहुत अधिक न भटकाएं।',
    env9: 'आपके रिकॉर्ड करते समय ऑडियो उठाया जा रहा है। ',
    instruction7:
      'कृपया अनिवार्य रूप से सही पहचान दस्तावेज़ अपलोड करें (सरकारी आईडी आदि)',
  },
  resumeParser: {
    title: 'पार्सर डेमो फिर से शुरू करें',
    description:
      'यह देखने के लिए कि हमारा सिस्टम उम्मीदवार के बायोडाटा का विश्लेषण और मूल्यांकन कैसे करता है, नीचे बायोडाटा अपलोड करें',
    dragResume:
      'बायोडाटा को यहां खींचें और छोड़ें, या फ़ाइल चुनने के लिए क्लिक करें (केवल पीडीएफ)',
    parsedResults: 'पार्स किए गए परिणाम',
    experience: 'अनुभव',
    personalDetails: 'व्यक्तिगत विवरण',
    totalYoE: 'अनुभव के कुल वर्ष',
    company: 'कंपनी',
    position: 'पद',
    duration: 'अवधि',
    skills: 'कौशल',
    education: 'शिक्षा',
    institute: 'संस्था',
    degree: 'डिग्री',
    major: 'प्रमुख',
    score: 'अंक',
    overall: 'कुल मिलाकर',
    betterFitNote:
      'ध्यान दें: 55-100 के समग्र बायोडाटा स्कोर वाले उम्मीदवार निर्दिष्ट नौकरी विवरण और आवश्यकताओं के आधार पर बेहतर उपयुक्त हैं।',
    name: 'नाम',
    email: 'ईमेल',
    phone: 'फ़ोन',
  },
  countdown: {
    recordIn: 'में रिकॉर्ड करें',
  },
  prepTimer: {
    timer: 'तैयारी समय',
  },
  candidateDetails: {
    back: 'पीछे',
    download: 'डाउनलोड करना',
    questions: 'प्रश्न',
    videoTranscript: 'वीडियो प्रतिलेख',
    resumeScore: 'स्कोर फिर से शुरू करें',
    workmapScore: 'वर्कमैप स्कोर',
    aiVideoScore: 'एआई वीडियो स्कोर',
    videoAssessment: 'वीडियो साक्षात्कार मूल्यांकन',
    text: 'मूलपाठ',
    voice: 'आवाज़',
    facialExpression: 'चेहरे की अभिव्यक्ति',
    professionalism: 'व्यावसायिकता',
    energyLevel: 'उर्जा स्तर',
    communication: 'संचार',
    sociability: 'सुजनता',
    pace: 'गति',
    charity: 'स्पष्टता',
    sentiments: 'भाव',
    energy: 'ऊर्जा',
    positivity: 'सकारात्मकता',
    positiveEmotion: 'सकारात्मक भावना',
    aboutThisAssessmentReport: 'इस मूल्यांकन रिपोर्ट के बारे में',
    introduction: 'परिचय',
    useOfReport: 'रिपोर्ट का उपयोग',
    confidentialityDisclaimer: 'गोपनीयता एवं अस्वीकरण',
    workmapAssessment: 'वर्कमैप मूल्यांकन',
    actualScore: 'वास्तविक स्कोर',
    resumeScoring: 'स्कोरिंग फिर से शुरू करें',
    excellent: 'उत्कृष्ट',
    high: 'उच्च',
    medium: 'मध्यम',
    low: 'कम',
    glossaryIndex: 'शब्दावली/सूचकांक',
    definitionOfWorkMapSkill: 'कार्य मानचित्र कौशल की परिभाषा',
    definitionOfVideoInterviewParameter:
      'वीडियो साक्षात्कार मापदंडों की परिभाषा',
    candidateSummary: 'उम्मीदवार सारांश',
    notAvailable: 'उपलब्ध नहीं है',
  },
  errors: {
    '404_CANDIDATE_DATA_NOT_FOUND':
      'मूल्यांकन देखने के लिए कृपया अपना वीडियो साक्षात्कार पूरा करें।',
    '404_ASSESSMENT_DATA_NOT_READY':
      'आपके वीडियो साक्षात्कार का अभी मूल्यांकन किया जा रहा है. ',
    '404_CANDIDATE_NOT_AVAILABLE':
      'आपने अभी तक कोई वीडियो साक्षात्कार नहीं लिया है. ',
    default: 'एक गलती हुई है। ',
  },
  companyDetails: {
    company: 'कंपनी',
    about: 'के बारे में',
    experience: 'अनुभव',
    apply: 'आवेदन करना',
    description: 'विवरण',
    viewJobDetailText: 'नौकरी विवरण देखें',
    readMore: 'और पढ़ें',
    readLess: 'कम पढ़ें',
  },
  dashboardPanel: {
    dashboard: 'डैशबोर्ड',
    hey: 'अरे',
    morningGreeting: 'शुभ प्रभात',
    afternoonGreeting: 'शुभ दोपहर',
    eveningGreeting: 'शुभ संध्या',
    upgrade: 'उन्नत करना',
    support: 'सहायता',
    signOut: 'साइन आउट',
    collapse: 'गिर जाना',
    faqs: 'पूछे जाने वाले प्रश्न',
    company: 'कंपनी',
    role: 'भूमिका',
    status: 'स्थिति',
    action: 'कार्रवाई',
    date: 'तारीख',
    recordNow: 'अभी रिकॉर्ड करें',
    responded: 'प्रतिक्रिया व्यक्त की',
    processing: 'प्रसंस्करण',
    viewDetails: 'विवरण देखें',
    personalInfo: 'व्यक्तिगत जानकारी',
    introVideo: 'परिचय वीडियो',
    score: 'अंक',
    email: 'ईमेल',
    phone: 'फ़ोन',
    summary: 'सारांश',
    topSkill: 'शीर्ष कौशल',
    workmapScore: 'वर्कमैप स्कोर',
    aiVideoScore: 'एआई वीडियो स्कोर',
    introText1: 'रोमांचक अंतर्दृष्टि के लिए तैयार हो जाइए। ',
    introText2: 'आपका परिचय वीडियो.',
    recordVideo: 'वीडियो रिकॉर्ड करें',
    settings: 'सेटिंग्स',
    yourInterviews: 'आपके साक्षात्कार',
  },
  candidatePortalSettings: {
    settings: 'सेटिंग्स',
    publicProfile: 'सार्वजनिक प्रालेख',
    profileVisibility: 'आपकी प्रोफ़ाइल सभी को दिखाई देती है.',
    closeAccount: ' खाता बंद करें',
    primaryConfirmationText: 'हमें आपके जाते हुए देखकर दुख हुआ। ',
    secondaryConfirmationText:
      'इससे आपका सारा डेटा और कोई भी मॉक इंटरव्यू मिट जाएगा जिसका आपने जवाब दिया होगा। ',
    reasoningHeading: ' हमें बताएं कि आप अपना खाता क्यों बंद कर रहे हैं:',
    primaryFeedbackText:
      'हम आपकी प्रतिक्रिया को महत्व देते हैं, लेकिन कृपया ध्यान दें कि हम इसमें आपके खाते के बारे में सबमिट की गई टिप्पणियों का जवाब देने में सक्षम नहीं हैं। ',
    clickHere: 'यहाँ क्लिक करें',
    buttonSectionPrimarytext: 'कृपया पुष्टि करें कि आपका खाता बंद हो गया है?',
    deleteButtonText: 'हाँ मैं',
    cancelButtonText: 'अभी नहीं',
    modalConfirmtext:
      'इस कार्रवाई से हमारे प्लेटफ़ॉर्म का उपयोग करके आपके द्वारा पूर्व में किए गए किसी भी एप्लिकेशन को हटा दिया जाएगा/वापस ले लिया जाएगा। ',
    confirm: ' पुष्टि करना',
  },
  purchaseBanner: {
    heading: 'अपनी विस्तृत एआई साक्षात्कार रिपोर्ट देखने के लिए अपग्रेड करें',
    subHeading: 'साक्षात्कार के अपने अगले दौर में आश्वस्त रहें।',
    launchAt: 'पर ऑफर लॉन्च करें',
    only: 'केवल',
    year: 'वर्ष',
  },
  additionalFormField: {
    chooseFileBtn: 'फाइलें चुनें',
    pleaseChooseAFileText: 'कृपया एक फ़ाइल चुनें',
  },
  domainQuestion: {
    domainAssessment: 'डोमेन मूल्यांकन',
    of: 'का',
    submit: 'जमा करना',
    questions: 'प्रश्न',
    answers: 'जवाब',
    submittedAssessment: 'आपने डोमेन मूल्यांकन प्रस्तुत कर दिया है.',
    autoRedirect:
      ' 3 सेकंड में स्वचालित रूप से अगले चरण पर रीडायरेक्ट हो रहा है...',
  },
  quitInterviewModal: {
    confirmQuit: 'साक्षात्कार छोड़ने की पुष्टि करें',
    areYouSure: 'क्या आप वाकई साक्षात्कार छोड़ना चाहते हैं?',
    confirm: 'पुष्टि करना',
    cancel: 'रद्द करना',
  },
  processingVideo: {
    instruction1: 'आपका वीडियो संसाधित हो रहा है...',
    instruction2: 'कृपया प्रतीक्षा करें',
    instruction3: 'इसमें केवल कुछ सेकंड लगेंगे...',
    instruction4: 'धैर्य रखें।',
    noAudioFound:
      'कोई ऑडियो नहीं मिला, कृपया स्पष्ट ऑडियो के साथ पुनः रिकॉर्ड करें। ',
  },
  audioError: {
    message:
      'हमें खेद है कि आपके वीडियो को ट्रांसक्रिप्ट करते समय कोई त्रुटि हुई। ',
  },
  reject: {
    thanksForInterest: 'आपकी रुचि के लिए धन्यवाद।',
    notQualified: 'दुर्भाग्यवश, आप इस आवेदन में आगे बढ़ने के लिए योग्य नहीं हैं।',
    bestWishes: 'हम आपको शुभकामनाएं देते हैं।',
  },
};
